<template>
  <div class="fixed flex justify-end bottom-0 right-0 w-[calc(100%-30px)] md:w-[470px] z-20 m-15 overflow-hidden pointer-events-none [&>*]:pointer-events-auto">
    <button
      type="button"
      class="flex items-center justify-center h-60 w-60 hover:bg-primary-500 hover:text-white transition-colors text-primary-500 bg-white border border-primary-500 z-10 relative"
      @click="contactBarIsOpen = !contactBarIsOpen"
    >
      <IconCross
        v-show="contactBarIsOpen"
        class="w-30 h-30"
      />
      <IconContact
        v-show="!contactBarIsOpen"
        class="w-40 h-40"
      />
    </button>
    <ul
      :class="[
        contactBarIsOpen ? 'translate-x-0' : 'translate-x-full',
        'absolute inset-0 mr-60 flex bg-white transition-transform transform-gpu border-primary-500 border-y border-l',
      ]"
    >
      <template
        v-for="(channel, key) in channels"
        :key="channel.name"
      >
        <li
          v-if="channel.to"
          class="w-full h-full relative"
        >
          <span
            v-if="key !== channels.length - 1"
            class="inset-y-20 right-0 w-1 bg-primary-500 absolute"
          />
          <nuxt-link
            v-if="channel.to"
            :to="channel.to"
            :aria-label="channel.name"
            :title="channel.name"
            class="flex h-full justify-center items-center text-primary-500 transition-colors hover:text-primary-300"
          >
            <component
              :is="channel.icon"
              class="w-40 h-40"
            />
          </nuxt-link>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
import { useState, useUtils, useI18n } from '#imports';

// Icons
import IconCross from '@/assets/img/icon-cross.svg';
import IconContact from '@/assets/img/icon-contact.svg';
import IconPhone from '@/assets/img/icon-phone.svg';
import IconMarker from '@/assets/img/icon-marker.svg';
import IconWhatsapp from '@/assets/img/icon-whatsapp.svg';
import IconCalendar from '@/assets/img/icon-calendar.svg';

// Props
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});

const { t } = useI18n();
const { phone } = useUtils();
const contactBarIsOpen = useState('contactBarIsOpen');
const channels = [
  { name: t('contact.call'), icon: IconPhone, to: (props.data?.contact?.phone ? `tel:${phone(props.data?.contact.phone)}` : null) },
  { name: t('contact.locations'), icon: IconMarker, to: (props.data?.contact?.locationsUrl ? props.data?.contact.locationsUrl : null) },
  { name: t('contact.whatsapp'), icon: IconWhatsapp, to: (props.data?.socials?.whatsapp ? `https://wa.me/${props.data?.socials.whatsapp}` : null) },
  { name: t('contact.appointment'), icon: IconCalendar, to: (props.data?.contact?.appointmentUrl ? props.data?.contact.appointmentUrl : null) },
];
</script>
