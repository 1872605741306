import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBannerLarge, LazyBeforeAfter, LazyEmbedVideo, LazyHero, LazyImageLarge, LazyIntro, LazyPhilosophy, LazyProductsSlider, LazyProducts, LazyQuote, LazyWysiwyg } from '#components'
const lazyGlobalComponents = [
  ["BannerLarge", LazyBannerLarge],
["BeforeAfter", LazyBeforeAfter],
["EmbedVideo", LazyEmbedVideo],
["Hero", LazyHero],
["ImageLarge", LazyImageLarge],
["Intro", LazyIntro],
["Philosophy", LazyPhilosophy],
["ProductsSlider", LazyProductsSlider],
["Products", LazyProducts],
["Quote", LazyQuote],
["Wysiwyg", LazyWysiwyg],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
