import { createGtm, useGtm } from '@gtm-support/vue-gtm';
import {
  defineNuxtPlugin,
  useRouter,
  useRuntimeConfig,
} from '#imports';

/**
 * Google Tagmanager plugin.
 *
 * @returns {Object} provide $gtm
 */
export default defineNuxtPlugin((nuxtApp) => {
  const { public: { GTM_ENABLED, GTM_ID, GTM_DEBUG } } = useRuntimeConfig();

  nuxtApp.vueApp.use(
    createGtm({
      id: GTM_ID,
      enabled: GTM_ENABLED === 'true',
      debug: GTM_DEBUG === 'true',
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false,
      defer: true,
    }),
  );

  return { provide: { gtm: useGtm() } };
});
