
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "de"
]

export const localeLoaders = {
  "nl": [{ key: "../src/assets/i18n/nl.json", load: () => import("../src/assets/i18n/nl.json" /* webpackChunkName: "locale__tmp_build_2c0c71ae_src_assets_i18n_nl_json" */), cache: true }],
  "de": [{ key: "../src/assets/i18n/de.json", load: () => import("../src/assets/i18n/de.json" /* webpackChunkName: "locale__tmp_build_2c0c71ae_src_assets_i18n_de_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "i18n_config_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "name": "nederlands",
      "code": "nl",
      "language": "nl-NL",
      "domain": "https://www.kotterliving.nl",
      "files": [
        "/tmp/build_2c0c71ae/src/assets/i18n/nl.json"
      ]
    },
    {
      "name": "deutsch",
      "code": "de",
      "language": "de-DE",
      "domain": "https://www.koetterliving.de",
      "files": [
        "/tmp/build_2c0c71ae/src/assets/i18n/de.json"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "assets/i18n/",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "https://www.kotterliving.nl",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "nederlands",
    "code": "nl",
    "language": "nl-NL",
    "domain": "https://www.kotterliving.nl",
    "files": [
      {
        "path": "/tmp/build_2c0c71ae/src/assets/i18n/nl.json"
      }
    ]
  },
  {
    "name": "deutsch",
    "code": "de",
    "language": "de-DE",
    "domain": "https://www.koetterliving.de",
    "files": [
      {
        "path": "/tmp/build_2c0c71ae/src/assets/i18n/de.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
