
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as entryOhopyGnoFAMeta } from "/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue?macro=true";
import { default as content0ptMQHzabgMeta } from "/tmp/build_2c0c71ae/src/pages/content.vue?macro=true";
import { default as indexPlkHrVwK0vMeta } from "/tmp/build_2c0c71ae/src/pages/products/index.vue?macro=true";
import { default as entryMSD6wrwcTwMeta } from "/tmp/build_2c0c71ae/src/pages/brands/entry.vue?macro=true";
import { default as entryueEZEWP6EMMeta } from "/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue?macro=true";
import { default as datav5OzJ9YhAWMeta } from "/tmp/build_2c0c71ae/src/pages/checkout/data.vue?macro=true";
import { default as index8zl9dr4djMMeta } from "/tmp/build_2c0c71ae/src/pages/inspiration/blog/index.vue?macro=true";
import { default as entry1iCHqixxZNMeta } from "/tmp/build_2c0c71ae/src/pages/inspiration/entry.vue?macro=true";
import { default as confirmation8sufNP1g4wMeta } from "/tmp/build_2c0c71ae/src/pages/checkout/confirmation.vue?macro=true";
import { default as formsVGBSBx66CFMeta } from "/tmp/build_2c0c71ae/src/pages/forms.vue?macro=true";
import { default as search7gj10ALgEiMeta } from "/tmp/build_2c0c71ae/src/pages/search.vue?macro=true";
import { default as stores3UU5j67LO3Meta } from "/tmp/build_2c0c71ae/src/pages/stores.vue?macro=true";
import { default as aboutVl5pIOnxodMeta } from "/tmp/build_2c0c71ae/src/pages/about.vue?macro=true";
import { default as indexzhPZwVXKLWMeta } from "/tmp/build_2c0c71ae/src/pages/checkout/index.vue?macro=true";
import { default as indexQs2RQG9tMAMeta } from "/tmp/build_2c0c71ae/src/pages/inspiration/index.vue?macro=true";
import { default as indexhWsylX056gMeta } from "/tmp/build_2c0c71ae/src/pages/index.vue?macro=true";
import { default as interior_45advicehvqDTOj1IJMeta } from "/tmp/build_2c0c71ae/src/pages/interior-advice.vue?macro=true";
import { default as indexab0dYw6sRqMeta } from "/tmp/build_2c0c71ae/src/pages/rooms/index.vue?macro=true";
import { default as contactlfU83uWGBLMeta } from "/tmp/build_2c0c71ae/src/pages/contact.vue?macro=true";
import { default as indexyfisgoI48nMeta } from "/tmp/build_2c0c71ae/src/pages/brands/index.vue?macro=true";
import { default as magazinetfjsV9n5zCMeta } from "/tmp/build_2c0c71ae/src/pages/magazine.vue?macro=true";
import { default as entry5vunrOyTtMMeta } from "/tmp/build_2c0c71ae/src/pages/products/entry.vue?macro=true";
import { default as component_45stubBk71oMB3BxMeta } from "/tmp/build_2c0c71ae/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubBk71oMB3Bx } from "/tmp/build_2c0c71ae/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "inspiratie/binnenkijkers/thuis-bij-karlijn-en-frank",
    path: "/inspiratie/binnenkijkers/thuis-bij-karlijn-en-frank",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "woonboulevard-hengelo",
    path: "/woonboulevard-hengelo",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "inspiratie/binnenkijkers/binnenkijken-in-een-licht-en-fris-interieur",
    path: "/inspiratie/binnenkijkers/binnenkijken-in-een-licht-en-fris-interieur",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "stoelen/eetkamerbanken",
    path: "/stoelen/eetkamerbanken",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "outlet/bankstel-outlet",
    path: "/outlet/bankstel-outlet",
    meta: {"handle":"outlet"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "merken/du-es-interior-stuff",
    path: "/merken/du-es-interior-stuff",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "vloerkleden/ovale-vloerkleden",
    path: "/vloerkleden/ovale-vloerkleden",
    meta: {"handle":"rugs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "merken/he-design",
    path: "/merken/he-design",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "vloerkleden",
    path: "/vloerkleden",
    meta: {"handle":"rugs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "woonwinkel-deventer",
    path: "/woonwinkel-deventer",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "merken/richmond-interiors",
    path: "/merken/richmond-interiors",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "inspiratie/blog/kim-kotter",
    path: "/inspiratie/blog/kim-kotter",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "meubelzaak-weerselo",
    path: "/meubelzaak-weerselo",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "woonaccessoires/spiegels",
    path: "/woonaccessoires/spiegels",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "bankstellen/velvet-banken",
    path: "/bankstellen/velvet-banken",
    meta: {"handle":"sofas"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "kasten/wandkasten",
    path: "/kasten/wandkasten",
    meta: {"handle":"closets"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "outlet/fauteuils-outlet",
    path: "/outlet/fauteuils-outlet",
    meta: {"handle":"outlet"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "stoelen/stoelen-outlet",
    path: "/stoelen/stoelen-outlet",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "merken/room108",
    path: "/merken/room108",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "merken/chill-line",
    path: "/merken/chill-line",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "winkelwagen/gegevens",
    path: "/winkelwagen/gegevens",
    meta: {"handle":"cartData"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/checkout/data.vue")
  },
  {
    name: "woonaccessoires/kleinmeubelen",
    path: "/woonaccessoires/kleinmeubelen",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "annuleren-wijzigen-retouren",
    path: "/annuleren-wijzigen-retouren",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "inspiratie/binnenkijkers",
    path: "/inspiratie/binnenkijkers",
    meta: {"handle":"interiorViewersOverview"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/index.vue")
  },
  {
    name: "woonaccessoires/kaarsen",
    path: "/woonaccessoires/kaarsen",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/binnenkijkers/de-woonstijl-van-zangeres-en-televisiepresentatrice-monique-smit",
    path: "/inspiratie/binnenkijkers/de-woonstijl-van-zangeres-en-televisiepresentatrice-monique-smit",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "kasten/tv-meubels",
    path: "/kasten/tv-meubels",
    meta: {"handle":"closets"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "disclaimer",
    path: "/disclaimer",
    meta: {"handle":"disclaimer"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "bankstellen",
    path: "/bankstellen",
    meta: {"handle":"sofas"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "verlichting/tafellampen",
    path: "/verlichting/tafellampen",
    meta: {"handle":"lighting"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "ruimtes/woonkamer",
    path: "/ruimtes/woonkamer",
    meta: {"handle":"rooms"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/entry.vue")
  },
  {
    name: "kasten/dressoirs",
    path: "/kasten/dressoirs",
    meta: {"handle":"closets"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "woonaccessoires/kussens",
    path: "/woonaccessoires/kussens",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "kasten/boekenkasten",
    path: "/kasten/boekenkasten",
    meta: {"handle":"closets"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "merken/cobra-art-company",
    path: "/merken/cobra-art-company",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "kasten",
    path: "/kasten",
    meta: {"handle":"closets"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "kasten/opbergkasten",
    path: "/kasten/opbergkasten",
    meta: {"handle":"closets"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "ruimtes/hal",
    path: "/ruimtes/hal",
    meta: {"handle":"rooms"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/entry.vue")
  },
  {
    name: "inspiratie/binnenkijkers/open-warm-en-vrolijk-binnenkijken-bij-bas-en-elles",
    path: "/inspiratie/binnenkijkers/open-warm-en-vrolijk-binnenkijken-bij-bas-en-elles",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "merken/chamada",
    path: "/merken/chamada",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "tafels/bureaus",
    path: "/tafels/bureaus",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "winkelwagen/bedankt",
    path: "/winkelwagen/bedankt",
    meta: {"handle":"cartConfirmation"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/checkout/confirmation.vue")
  },
  {
    name: "ruimtes/werkkamer",
    path: "/ruimtes/werkkamer",
    meta: {"handle":"rooms"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/entry.vue")
  },
  {
    name: "outlet",
    path: "/outlet",
    meta: {"handle":"outlet"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/blog/ontdek-de-nieuwe-brochure-van-kotter-living",
    path: "/inspiratie/blog/ontdek-de-nieuwe-brochure-van-kotter-living",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "inspiratie/woonstijlen",
    path: "/inspiratie/woonstijlen",
    meta: {"handle":"interiorStylesOverview"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/index.vue")
  },
  {
    name: "woonaccessoires/ornamenten",
    path: "/woonaccessoires/ornamenten",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "woonaccessoires/windlichten",
    path: "/woonaccessoires/windlichten",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "ladiesnight",
    path: "/ladiesnight",
    meta: {"handle":"forms"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/forms.vue")
  },
  {
    name: "woonboulevard-oldenzaal",
    path: "/woonboulevard-oldenzaal",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "outlet/bartafels-outlet",
    path: "/outlet/bartafels-outlet",
    meta: {"handle":"outlet"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/woonstijlen/ethno-interieur",
    path: "/inspiratie/woonstijlen/ethno-interieur",
    meta: {"handle":"interiorStyles"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "zoekresultaten",
    path: "/zoekresultaten",
    meta: {"handle":"search"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/search.vue")
  },
  {
    name: "tafels/bijzettafels",
    path: "/tafels/bijzettafels",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "tafels/salontafels",
    path: "/tafels/salontafels",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/blog/tips-en-tricks-vakkenkast",
    path: "/inspiratie/blog/tips-en-tricks-vakkenkast",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "inspiratie/binnenkijkers/neem-een-kijkje-in-de-eetkamer-van-wonenmet-ik",
    path: "/inspiratie/binnenkijkers/neem-een-kijkje-in-de-eetkamer-van-wonenmet-ik",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "stoelen/barstoelen-outlet",
    path: "/stoelen/barstoelen-outlet",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "merken/brons-interieur",
    path: "/merken/brons-interieur",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "inspiratie/binnenkijkers/modern-maar-landelijk-binnenkijken-bij-robert",
    path: "/inspiratie/binnenkijkers/modern-maar-landelijk-binnenkijken-bij-robert",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "outlet/vloerkleden-outlet",
    path: "/outlet/vloerkleden-outlet",
    meta: {"handle":"outlet"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "woonwinkels",
    path: "/woonwinkels",
    meta: {"handle":"stores"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/stores.vue")
  },
  {
    name: "stoelen",
    path: "/stoelen",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/binnenkijkers/ontdek-een-warme-woonstijl-van-binnen",
    path: "/inspiratie/binnenkijkers/ontdek-een-warme-woonstijl-van-binnen",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "inspiratie/binnenkijkers/neem-een-kijkje-in-de-woonkamer-van-bas-en-elles",
    path: "/inspiratie/binnenkijkers/neem-een-kijkje-in-de-woonkamer-van-bas-en-elles",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "bezorgen-en-afhalen",
    path: "/bezorgen-en-afhalen",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "inspiratie/blog/het-interieur-van-monique-smit-door-kotter-living",
    path: "/inspiratie/blog/het-interieur-van-monique-smit-door-kotter-living",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "algemene-voorwaarden",
    path: "/algemene-voorwaarden",
    meta: {"handle":"terms"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "inspiratie/binnenkijkers/the-different-story-of-kim-kotter-en-jaap-reesema",
    path: "/inspiratie/binnenkijkers/the-different-story-of-kim-kotter-en-jaap-reesema",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "merken/nyawa",
    path: "/merken/nyawa",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "inspiratie/binnenkijkers/hotel-chic",
    path: "/inspiratie/binnenkijkers/hotel-chic",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "inspiratie/binnenkijkers/binnenkijken-bij-onze-collega-en-interieuradviseur-kayleigh",
    path: "/inspiratie/binnenkijkers/binnenkijken-bij-onze-collega-en-interieuradviseur-kayleigh",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "bankstellen/hoekbanken",
    path: "/bankstellen/hoekbanken",
    meta: {"handle":"sofas"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "meubelzaak-tubbergen",
    path: "/meubelzaak-tubbergen",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "inspiratie/blog/de-5-populairste-banken-van-kotter-living",
    path: "/inspiratie/blog/de-5-populairste-banken-van-kotter-living",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "woonaccessoires/kandelaren",
    path: "/woonaccessoires/kandelaren",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "vacatures",
    path: "/vacatures",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "outlet/eettafels-outlet",
    path: "/outlet/eettafels-outlet",
    meta: {"handle":"outlet"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "over-ons",
    path: "/over-ons",
    meta: {"handle":"about"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/about.vue")
  },
  {
    name: "ruimtes/slaapkamer",
    path: "/ruimtes/slaapkamer",
    meta: {"handle":"rooms"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/entry.vue")
  },
  {
    name: "inspiratie/woonstijlen/hotel-chic",
    path: "/inspiratie/woonstijlen/hotel-chic",
    meta: {"handle":"interiorStyles"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "merken/ztahl-illuminators-by-dijkos",
    path: "/merken/ztahl-illuminators-by-dijkos",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "woonaccessoires",
    path: "/woonaccessoires",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "woonboulevard-almelo",
    path: "/woonboulevard-almelo",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "inspiratie/blog/create-your-sofa",
    path: "/inspiratie/blog/create-your-sofa",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "inspiratie/blog/lisette-lubbers",
    path: "/inspiratie/blog/lisette-lubbers",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "kasten/buffetkasten",
    path: "/kasten/buffetkasten",
    meta: {"handle":"closets"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "tafels/bartafels",
    path: "/tafels/bartafels",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/blog",
    path: "/inspiratie/blog",
    meta: {"handle":"blogOverview"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/index.vue")
  },
  {
    name: "inspiratie/woonstijlen/clean-soft",
    path: "/inspiratie/woonstijlen/clean-soft",
    meta: {"handle":"interiorStyles"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "outlet/kasten-outlet",
    path: "/outlet/kasten-outlet",
    meta: {"handle":"outlet"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "merken/karpi-b-v",
    path: "/merken/karpi-b-v",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "garantie-service",
    path: "/garantie-service",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "winkelwagen",
    path: "/winkelwagen",
    meta: {"handle":"cartOverview"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/checkout/index.vue")
  },
  {
    name: "meubelzaak-ootmarsum",
    path: "/meubelzaak-ootmarsum",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "verlichting/vloerlampen",
    path: "/verlichting/vloerlampen",
    meta: {"handle":"lighting"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/blog/eetkamerstoelen-bij-kotter-living",
    path: "/inspiratie/blog/eetkamerstoelen-bij-kotter-living",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "stoelen/barkrukken",
    path: "/stoelen/barkrukken",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "meubelzaak-delden",
    path: "/meubelzaak-delden",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "woonaccessoires/schelpendecoratie",
    path: "/woonaccessoires/schelpendecoratie",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "tafels/boomstamtafels",
    path: "/tafels/boomstamtafels",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie",
    path: "/inspiratie",
    meta: {"handle":"inspirationOverview"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/index.vue")
  },
  {
    name: "meubelzaak-haaksbergen",
    path: "/meubelzaak-haaksbergen",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "tafels/bartafels-outlet",
    path: "/tafels/bartafels-outlet",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/blog/top-5-banken-van-passe-partout",
    path: "/inspiratie/blog/top-5-banken-van-passe-partout",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "inspiratie/binnenkijkers/kijk-binnen-in-dit-ruime-appartement-met-een-beige-interieur",
    path: "/inspiratie/binnenkijkers/kijk-binnen-in-dit-ruime-appartement-met-een-beige-interieur",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "woonboulevard-zwolle",
    path: "/woonboulevard-zwolle",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "woonwinkel-rijssen",
    path: "/woonwinkel-rijssen",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: {"handle":"privacy"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "__home__",
    path: "/",
    meta: {"handle":"home"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/index.vue")
  },
  {
    name: "meubels-vriezenveen",
    path: "/meubels-vriezenveen",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "outlet/barstoelen-outlet",
    path: "/outlet/barstoelen-outlet",
    meta: {"handle":"outlet"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "vloerkleden/ronde-vloerkleden",
    path: "/vloerkleden/ronde-vloerkleden",
    meta: {"handle":"rugs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "interieuradvies",
    path: "/interieuradvies",
    meta: {"handle":"interiorAdvice"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/interior-advice.vue")
  },
  {
    name: "woonaccessoires/klokken",
    path: "/woonaccessoires/klokken",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "ruimtes",
    path: "/ruimtes",
    meta: {"handle":"roomsOverview"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/rooms/index.vue")
  },
  {
    name: "inspiratie/binnenkijkers/van-een-huis-naar-thuis-gemaakt-een-kijkje-in-de-harmonie-van-stijl-en-comfort",
    path: "/inspiratie/binnenkijkers/van-een-huis-naar-thuis-gemaakt-een-kijkje-in-de-harmonie-van-stijl-en-comfort",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "inspiratie/blog/de-meubels-van-richmond-interiors",
    path: "/inspiratie/blog/de-meubels-van-richmond-interiors",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "servicemelding",
    path: "/servicemelding",
    meta: {"handle":"serviceMessage"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/contact.vue")
  },
  {
    name: "woonaccessoires/manden",
    path: "/woonaccessoires/manden",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/binnenkijkers/neem-een-kijkje-in-een-interieur-dat-hotel-chic-combineert-met-neutrale-kleuren",
    path: "/inspiratie/binnenkijkers/neem-een-kijkje-in-een-interieur-dat-hotel-chic-combineert-met-neutrale-kleuren",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "woonaccessoires/wanddecoratie",
    path: "/woonaccessoires/wanddecoratie",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "outlet/stoelen-outlet",
    path: "/outlet/stoelen-outlet",
    meta: {"handle":"outlet"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: {"handle":"contact"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/contact.vue")
  },
  {
    name: "inspiratie/binnenkijkers/different-people-different-graphics-binnenkijken-bij-lisette-lubbers",
    path: "/inspiratie/binnenkijkers/different-people-different-graphics-binnenkijken-bij-lisette-lubbers",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "woonwinkel-losser",
    path: "/woonwinkel-losser",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "merken",
    path: "/merken",
    meta: {"handle":"brandsOverview"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/index.vue")
  },
  {
    name: "cbw-erkende-winkel",
    path: "/cbw-erkende-winkel",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "verlichting/lichtbronnen",
    path: "/verlichting/lichtbronnen",
    meta: {"handle":"lighting"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "woonaccessoires/overige-woonaccessoires",
    path: "/woonaccessoires/overige-woonaccessoires",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "vloerkleden/rechthoekige-vloerkleden",
    path: "/vloerkleden/rechthoekige-vloerkleden",
    meta: {"handle":"rugs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "tafels/versteend-hout-salontafels",
    path: "/tafels/versteend-hout-salontafels",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "kasten/badmeubels",
    path: "/kasten/badmeubels",
    meta: {"handle":"closets"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "woonaccessoires/dienbladen",
    path: "/woonaccessoires/dienbladen",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "tafels/sidetables",
    path: "/tafels/sidetables",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "stoelen/landelijke-stoelen",
    path: "/stoelen/landelijke-stoelen",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "tafels",
    path: "/tafels",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/woonstijlen/stijlvol-wonen",
    path: "/inspiratie/woonstijlen/stijlvol-wonen",
    meta: {"handle":"interiorStyles"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "meubelplein-enschede",
    path: "/meubelplein-enschede",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "stoelen/fauteuils",
    path: "/stoelen/fauteuils",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/binnenkijkers/ontdek-de-woonstijl-van-wonenmet-ik",
    path: "/inspiratie/binnenkijkers/ontdek-de-woonstijl-van-wonenmet-ik",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "woonwinkel-borne",
    path: "/woonwinkel-borne",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "aanmelden",
    path: "/aanmelden",
    meta: {"handle":"forms"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/forms.vue")
  },
  {
    name: "inspiratie/binnenkijkers/hotel-chic-binnenkijken-bij-huisjeinborne",
    path: "/inspiratie/binnenkijkers/hotel-chic-binnenkijken-bij-huisjeinborne",
    meta: {"handle":"interiorViewers"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "tafels/sofatafels",
    path: "/tafels/sofatafels",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "merken/arte",
    path: "/merken/arte",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "inspiratie/woonstijlen/industrieel-interieur",
    path: "/inspiratie/woonstijlen/industrieel-interieur",
    meta: {"handle":"interiorStyles"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "merken/passe-partout",
    path: "/merken/passe-partout",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "merken/pmp-furniture-nix",
    path: "/merken/pmp-furniture-nix",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "inspiratie/blog/haal-de-lente-in-huis",
    path: "/inspiratie/blog/haal-de-lente-in-huis",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "tafels/eettafels",
    path: "/tafels/eettafels",
    meta: {"handle":"tables"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "merken/bocx-interiors",
    path: "/merken/bocx-interiors",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "ruimtes/eetkamer",
    path: "/ruimtes/eetkamer",
    meta: {"handle":"rooms"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/entry.vue")
  },
  {
    name: "woonwinkel-denekamp",
    path: "/woonwinkel-denekamp",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "inspiratie/blog/meubels-van-room108-in-jouw-interieur",
    path: "/inspiratie/blog/meubels-van-room108-in-jouw-interieur",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "inspiratie/blog/de-perfecte-eethoek-creeren-met-kotter-living",
    path: "/inspiratie/blog/de-perfecte-eethoek-creeren-met-kotter-living",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "stoelen/fauteuils-outlet",
    path: "/stoelen/fauteuils-outlet",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "bankstellen/stoffen-bank",
    path: "/bankstellen/stoffen-bank",
    meta: {"handle":"sofas"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "merken/eleonora",
    path: "/merken/eleonora",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "woonwinkel-nijverdal",
    path: "/woonwinkel-nijverdal",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "verlichting",
    path: "/verlichting",
    meta: {"handle":"lighting"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "onderhoud",
    path: "/onderhoud",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "dit-is-kotter-living",
    path: "/dit-is-kotter-living",
    meta: {"handle":"landingPages"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/content.vue")
  },
  {
    name: "bankstellen/u-banken",
    path: "/bankstellen/u-banken",
    meta: {"handle":"sofas"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "bankstellen/luxe-banken",
    path: "/bankstellen/luxe-banken",
    meta: {"handle":"sofas"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "woonaccessoires/hockers",
    path: "/woonaccessoires/hockers",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "verlichting/hanglampen",
    path: "/verlichting/hanglampen",
    meta: {"handle":"lighting"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "stoelen/eetkamerstoelen",
    path: "/stoelen/eetkamerstoelen",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "stoelen/kuipstoelen",
    path: "/stoelen/kuipstoelen",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "stoelen/relaxstoelen",
    path: "/stoelen/relaxstoelen",
    meta: {"handle":"chairs"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "magazine",
    path: "/magazine",
    meta: {"handle":"magazine"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/magazine.vue")
  },
  {
    name: "merken/light-living",
    path: "/merken/light-living",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "inspiratie/blog/woontrends-van-2025",
    path: "/inspiratie/blog/woontrends-van-2025",
    meta: {"handle":"blogpost"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/blog/entry.vue")
  },
  {
    name: "merken/mondiart",
    path: "/merken/mondiart",
    meta: {"handle":"brands"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/brands/entry.vue")
  },
  {
    name: "woonaccessoires/vazen",
    path: "/woonaccessoires/vazen",
    meta: {"handle":"accessories"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/index.vue")
  },
  {
    name: "inspiratie/woonstijlen/japandi-interieur",
    path: "/inspiratie/woonstijlen/japandi-interieur",
    meta: {"handle":"interiorStyles"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/inspiration/interior-viewers/entry.vue")
  },
  {
    name: "product",
    path: "/:product",
    meta: {"handle":"product"},
    component: () => import("/tmp/build_2c0c71ae/src/pages/products/entry.vue")
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/geen-categorie",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/banken",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/stoelen/fauteuils",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/accessoires",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/series-nl/richmond-interiors/*",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/tafels/*",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tafels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/kasten",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/stoelen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/badkamermeubels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/vloerkleden",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/wanddecoratie",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/kussens",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/verlichting",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/krukjes",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tafels/eettafels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tafels/salontafels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tafels/bartafels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tafels/bijzettafels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tafels/sidetables",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/badkamermeubels/badkamerkasten",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/badkamermeubels/badkamermeubels-oldenzaal",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/banken/bankstellen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/banken/hoekbanken",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/stoelen/relaxfauteuils",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/kasten/tv-meubels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/kasten/dressoirs",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/kasten/opbergkast",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/kasten/buffetkast",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/kasten/kast-op-maat",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/stoelen/eetkamerstoelen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/stoelen/barstoelen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/stoelen/barkrukken",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/stoelen/eetkamerbanken",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/inspiratie/blog/ethno-interieur",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/inspiratie/blog/hotel-chique-in-jouw-interieur",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/inspiratie/blog/trend-de-japandi-woonstijl",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/inspiratie/blog/clean-soft",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/het-anker",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/tower-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/kasten/boekenkasten",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/kasten/klein-meubels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/easy-sofa",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/spiegels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/outlet",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/kotter-collectie",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/nix-design",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/ornamenten",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/verf",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/h-e-design",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/ted-sparks",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/geurartikelen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/cadeaubon",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/ted-sparks-woonaccessoires",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/smaqq",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tafels/bureau",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/suns-tuinmeubelen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/suns",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tafels/boomstamtafels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tafels/salontafels/versteend-hout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/klokken",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/vazen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/woonaccessoires/kaarsenhouder",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/puuur-luxury-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/meubels/tuinmeubelen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/merken/chill-line",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/kotter-outdoor-collectie",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/suns-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorscollectiesboxx-knox",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorsmeubelskasten",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorsmeubelskastendressoirs",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorscollectieshighline",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorsmeubelstafelseettafels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorscollectiesmixmatch",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorscollectiesraffles",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorscollectiesoakdale",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorsmeubelskastenboekenkasten",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorsmeubelskastenvitrines",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorsmeubelskastentv-meubels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorsmeubelstafelssalontafels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorscollectiesslaapkamerslaapkamer",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorscollectiesoakura",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorsmeubelstafelswandtafels",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/richmond-interiorswoonaccessoireswindlichten",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/bijzettafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/winkel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/modules",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/onze-geschiedenis",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/ruimtes/badkamer",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/ruimtes/eethoek",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/kotter-collectie",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/garantie",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/gratis-3d-interieurontwerp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/privacy-policy",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/copyright",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/veel-gestelde-vragen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/kennisbank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/gezien-bij",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/gezien-bij/lisette-lubbers",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/gezien-bij/kim-kotter",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/reservering",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/reservering/bedankt",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/terugbetalen_retournering",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/contact/bedankt",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/gezien-bij/monique-smit",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/kotter-nijverdal",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-daan-antraciet-touareg-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-menno-antraciet-cowboy-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-menno-bruin-cowboy-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-menno-cognac-cowboy-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kabinet-mango-1-deurs-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-meubel-mango-1-deur-en-1-lade-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-meubel-mango-2-deurs-en-1-lade-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-mango-3-deurs-en-3-laden-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-mango-met-metalen-poot-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-mango-met-metalen-poot-100x100-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-mango-met-metalen-poot-140x80-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-mango-met-metalen-poot-200x100-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-meubel-mango-150-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-meubel-mango-200-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/countertafel-mango-90-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenkast-mango-94-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-mango-met-metalen-poot-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-mango-met-metalen-poot-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/decorative-stand-nolan",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanging-lamp-emin-incl-gold-shades",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/clock-dean",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampshade-marly-chelsea-silver-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampshade-marly-chelsea-silver-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampshade-marly-chelsea-silver-5",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/trolley-hennesy-met-zwart-glas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pillar-raffles-set-van-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-linsey-draaibaar-khaki-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-linsey-draaibaar-stone-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenkast-oakura-2-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/buffetkast-oakura-2x2-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-oakura-3-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-oakura-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-blackbone-silver-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-blackbone-goud-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-raffles-4-deuren-gerecyceld-hout-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-scarlett-black-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-scarlett-pink-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-pippa-stone-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-pippa-green-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-pippa-khaki-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-pippa-pink-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-pippa-stone-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bartafel-osteria-rond-80o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-osteria-square-70x70-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-dynasty-rond-130o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-dynasty-200-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-kensington-rond-140o-incl-glas-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-perla-khaki-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-perla-pink-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-perla-rust-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-perla-stone-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-indy-khaki-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-indy-stone-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaistoel-mila-green-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaistoel-mila-pink-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaistoel-mila-stone-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaistoel-jamie-pink-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaistoel-jamie-stone-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kinderstoel-charly-pink-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pilaar-oakura-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pilaar-levanto-set-van-2-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/nachtkastje-blackbone-silver-1-lade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-blackbone-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-blackbone-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ciawi-versteend-hout-salontafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/garung-versteend-hout-salontafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/nachtkastje-blackbone-goud-1-lade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/trolley-x-o-goud-met-glas-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzetafel-gordon-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-paramount-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-oakura-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-lacey-met-marmeren-top-set-van-2-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-mona-met-marmeren-top-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoektafel-raffles-recyceld-hout-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-raffles-recyceld-hout-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-oakura-blok-c-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-oakura-blok-h-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-orion-60o-met-bruin-marmer-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-orion-80o-met-bruin-marmer-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-orion-met-bruin-marmer-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-dynasty-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-levanto-set-van-2-rond-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-silver-150x80-block-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-silver-90x90-block-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-blackbone-silver-50x50-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-silver-90x90-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-blackbone-goud-50x50-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-goud-90x90-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-goud-160x40-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-goud-150x80-block-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-goud-90x90-block-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-brooke-set-of-2-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/table-nolan-set-of-3-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/table-asher-set-of-2-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/table-lyam-aluminium-silver-70o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/table-milo-aluminium-champion-gold-70o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/table-finn-square-set-of-2-champion-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-vittorio-set-van-3-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-calloway-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-calloway-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-calloway-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-raffles-recyceld-hout-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-clara-stone-velvet-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-chrissy-khaki-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-chrissy-stone-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-oakura-3-laden-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-185-blackbone-silver-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-220-blackbone-silver-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-185-blackbone-goud-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-220-blackbone-goud-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-kensington-2-planken-180-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-raffles-4-deuren-gerecyceld-hout-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-raffles-recyceld-hout-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-blackbone-silver-7-planken-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-blackbone-silver-2-deuren-laag-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-blackbone-goud-2-deuren-laag-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-blackbone-goud-7-planken-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-raffles-2-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-oakura-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-blackbone-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-blackbone-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-calloway-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-raffles-recyceld-hout-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-meubel-hudson-2-laden-185-cm-zwart-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/cigaru-versteend-hout-salontafel-besar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/cigaru-versteend-hout-salontafel-sedang",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/helsinki-tv-meubel-160-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/helsinki-tv-meubel-200-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/helsinki-dressoir",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/helsinki-kabinet-120-cm-laag",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/helsinki-kabinet-120-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/helsinki-kabinet-150-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/helsinki-kabinet-200-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klaza-vloerkleed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lima-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lena-barstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lumos-barkruk",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-dressoir-met-4-deuren-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-dressoir-met-4-deuren-bruin",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-tv-meubel-met-4-deuren-bruin",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-tv-meubel-met-4-deuren-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-opbergkast-met-2-deuren-bruin",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-opbergkast-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-tv-meubel-met-6-deuren-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-tv-meubel-6-deuren-bruin",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-boekenkast-bruin",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/remi-boekenkast-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kubus-hocker-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/medusa-bank-passe-partout-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/zico-hoekbank-passe-partout-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/zola-hoekbank-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/alberto-hoekbank-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/livio-hoekbank-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/oslo-barstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/alvie-vloerkleed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-yona",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/gianni-bank-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lazaro-bank-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/medusa-bank-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/deco-object-beer-goud-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/deco-object-beer-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bloempot-donna-60%c2%9d-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bloempot-donna-40%c2%9d-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-tygo-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-tygo-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-tiko-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-ruan-goud-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-ruan-goud-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-milas-zwart-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-kiera-zwart-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-kiera-zwart-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-kiera-goud-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-kiera-goud-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-feray-zwart-medium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-batiste-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-marie-lou-2-laden-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-macaron-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-bliss-black-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vase-xavi-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-zaya-klein-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-zara-groot-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-shelly-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-shelly-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-shelena-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-shelby-pink-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-shelby-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-shani-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-shani-groot-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-selby-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-selby-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-remzi-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kisses-zwart-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kisses-zwart-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kisses-goud-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kisses-goud-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kenji-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kenji-groot-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-juun-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-jamey-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-jamey-groot-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-hailey-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-hailey-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-gina-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-gina-bruin-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-flore-klein-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-flore-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-fergy-crocodile-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-esila-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-dylano-43%c2%9d-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-danic-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-danic-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-cilou-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-celina-groot-bronze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-ceciel-set-van-2-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-callisto-groot-silver-finish-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-arturo-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-arturo-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-alida-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-alida-groot-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-classio-4-deuren-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-tetrad-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-tabitha-feather-stone-stone-velvet-fire-retardant-feather-velvet-stone-hd001-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-sara-white-chenille-fire-retardant-fr-bergen-900-white-chenille-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-sara-khaki-velvet-fire-retardant-fr-quartz-903-khaki-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-piper-antraciet-velvet-fire-retardant-quartz-antraciet-801-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-odessa-white-boucl-brushed-gold-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-jordan-khaki-velvet-fire-retardant-fr-quartz-903-khaki-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-jordan-khaki-velvet-fire-retardant-fr-bergen-900-white-chenille-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-indigo-white-boucl-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-harper-white-boucl-brushed-gold-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-giovanna-feather-stone-stone-velvet-fire-retardant-feather-velvet-stone-hd001-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-gia-white-boucl-fire-retardant-fr-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-gia-khaki-velvet-fire-retardant-fr-quartz-903-khaki-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-gia-antraciet-velvet-fire-retardant-fr-quartz-801-antraciet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-fallon-white-boucl-black-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-emerson-sand-velvet-fire-retardant-genova-904-sand-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-darby-white-boucl-black-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-chelsea-black-velvet-silver-quartz-black-800-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-cannon-white-boucl-brushed-gold-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-pauwel-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-maud-medium-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-maud-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-herringbone-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-catana-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-paramount-goud-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-patagonia-dark-coffee-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-macaron-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-levanto-95%c2%9d-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-levanto-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-herringbone-140x70-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-harvey-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-griffin-75%c2%9d-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-griffin-75%c2%9d-brushed-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-cossai-80%c2%9d-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-bliss-91%c2%9d-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-bliss-90%c2%9d-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-bliss-70%c2%9d-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-baccarat-set-van-2-dark-coffee-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-arsenio-set-van-2-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-tribe-41%c2%9d-white-boucl-fire-retardant-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-fenna-sand-furry-himalaya-902-sand-furry-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-emmy-70%c2%9d-white-boucl-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-emmy-425%c2%9d-white-boucl-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-candice-zigzag-60x60-iv-21021-big-zigzag-8022-twing-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-candice-zigzag-50x70-iv-21021-big-zigzag-8022-twing-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-candice-zigzag-45x45-iv-21021-big-zigzag-8022-twing-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-hudson-2-deurs-en-2-laden-zwart-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-hudson-3-deurs-en-2-laden-zwart-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-hudson-200x100-cm-zwart-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-industrieel-2-deurs-en-6-laden-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-meubel-industrieel-1-deurs-en-3-laden-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-meubel-industrieel-2-deurs-en-1-lade-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-marmer-50x50cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-lyndi-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-lily-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-layla-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-yaira-silver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-yaira-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-yaira-black-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-osiris-set-van-2-zwart-goud-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-osiris-set-van-2-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-hond-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-divon-groot-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-chenna-zwart-goud-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-chady-zwart-goud-medium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-chady-zwart-goud-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-blossom-bronze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-amany-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-fenna-white-chenille-fire-retardant-fr-bergen-900-white-chenille-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boomstam-tafel-met-spinpoot-200x100-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-berlin-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-canton-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-carcia-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-cornwall-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-florian-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-hamilton-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-helsinki-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-hetty-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/madrid-eetkamerstoel-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamerstoel-lyon-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-carmen-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-joelle-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-diane-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-noelle-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-elena-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-michelle-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-victoria-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-sophie-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-fenna-sand-furry-himalaya-902-sand-furry-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaistoel-maxime-sand-furry-himalaya-902-sand-furry-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaistoel-maxime-khaki-velvet-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draai-fauteuil-kendall-white-furry-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draai-fauteuil-kendall-khaki-velvet-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-baccarat-4-deuren-dark-coffee-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-willy-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-valerio-40%c2%9d-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-macaron-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-griffin-50%c2%9d-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-griffin-50%c2%9d-goud-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-ethan-46%c2%9d-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-ethan-46%c2%9d-brushed-goud-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-ethan-38%c2%9d-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-ethan-38%c2%9d-goud-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-denzell-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-denzell-brushed-goud-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-bliss-36%c2%9d-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bartafel-blackbone-gold-160-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-imani-black-velvet-fire-retardant-quartz-black-800-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-gia-khaki-velvet-fire-retardant-fr-quartz-903-khaki-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-gia-antraciet-velvet-fire-retardant-fr-quartz-801-antraciet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/opberg-box-cobe-zwart-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/opberg-box-cobe-wit-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-annabelle-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-gigi-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-christina-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ferro-eetkamerstoel-zonder-armleuning-tower-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-elise-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamerstoel-ferro-met-armleuning-tower-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-christiane-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoekbank-aymee-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoekbank-juliette-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoekbank-lisette-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoekbank-lucia-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoekbank-clarisse-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoekbank-isabelle-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoekbank-leona-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoekbank-julia-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/goa-salontafel-set-van-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/mia-salontafel-set-van-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/avelino-eettafel-220x100-railway-hout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/avelino-opbergkast-met-2-deuren",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/avelino-dressoir-met-4-deuren",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/avelino-tv-meubel-180-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/avelino-salontafel-railway-hout-met-stalen-frame",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/joshua-eiken-boomstamtafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/joshua-eettafel-opgedikt",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoekbank-bryce-bank-kotter-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/emma-versteend-hout-salontafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/badkamermeubel-gijs",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/willy-teak-badkamermeubel-120-cm-breed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/oman-teak-badkamermeubel-120-cm-breed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/holly-teak-badkamermeubel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/chris-teak-badkamermeubel-105-cm-breed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/collin-teak-badkamermeubel-105-cm-breed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sunshine-teak-badkamermeubel-140-cm-breed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tara-teak-badkamerkast-staand",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tara-teak-badkamermeubel-105-cm-breed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/leon-teak-badkamermeubel-105-cm-breed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ivan-teak-badkamermeubel-enkel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/django-teak-badkamermeubel-hangend",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/zico-hoekbank-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/george-eettafel-eiken-massief-blad",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tony-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/yunta-tree-trunk-tafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/rona-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jules-bank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-uit-china-5647",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-dressoir-china-5532",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-opbergkast-uit-china",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-565",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-321",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-231",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-5636",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-11201",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-11222",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-11083",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-5426",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/andrew-ovale-tafel-met-schuine-zijkant",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/andrew-ovale-eettafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandpaneel-driftwood-rond",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dex-badkamermeubel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/harvey-badkamermeubel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dax-badkamermeubel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/houten-ladder",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kim-fauteuil-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kim-xl-fauteuil-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bram-fauteuil-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/cindy-fauteuil-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/metro-fauteuil-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/elisa-fauteuil-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/julia-fauteuil-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/love-shaggy-vloerkleed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/manon-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/brent-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/netflix-bank-kotter-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ezra-bank-kotter-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/maverick-bank-kotter-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandpaneel-driftwood-oog-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barkast-bloomingville-2-deuren-shagreen-goud",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-bloomingville-4-deuren-shagreen-goud",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-bloomingville-4-deuren-shagreen-goud",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bloomingville-salontafel-set-van-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vivian-fauteuil",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamerstoel-paulette-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamerstoel-rachel-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-daan-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-peter-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-maria-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-lars-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-rafael",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-rousseau",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-radisson",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-rimini",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pastille-lounge-fauteuil-passepartout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/opberg-box-beez-zwart-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/opberg-box-beez-wit-groot-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-marlin-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/juwelen-box-jeade-beige-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/faux-koraal-julian-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dog-chihuahua-pink-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dog-bul-pink-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dienblad-demian-zilver-set-van-2-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dienblad-demian-goud-set-van-2-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/decoratieve-standaard-godiva-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/decoratieve-standaard-calypso-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenstandaard-krokodil-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/zola-bank-passepartout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pommeline-bank-passepartout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/avelino-bank-passepartout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pastille-swing-fauteuil-rond-passepartout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/toya-lounge-fauteuil-passepartout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/amany-kandelaar-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/splendid-vloerkleed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/luxury-vloerkleed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tim-draaifauteuil",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/chevy-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boston-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/toro-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lingus-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/silver-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/rosita-fauteuil",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-arvin-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-irvin-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-lennox-medium-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-lennox-klein-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-fenton-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-earlson-goud-medium-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-eaden-zilver-groot-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-emeray-zilver-medium-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windicht-aylmer-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-avius-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windicht-oswell-zwart-goud-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-oxley-zilver-groot-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-orvyn-zilver-klein-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-orren-zwart-nickel-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-holly-goud-met-groot-gewei-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-harvey-goud-met-klein-geweil-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-macy-met-gewei-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10039",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10040",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10041",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10042",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10043",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10044",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10045",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10046",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10047",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10049",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10050",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10051",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10052",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10055",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10056",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10061",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10062",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10064",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10066",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/deur-met-spiegel-uit-india-x10068",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-uit-india-x10069",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-uit-india-x10070",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10073",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10074",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-deur-met-spiegel-x10075",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/plantenbak-lowen-set-van-2-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-loyd-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-densley-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-delmor-medium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-dailen-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-danito-medium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-selah-aluminium-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-sjarel-aluminium-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-siara-medium-aluminium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-milano-goud-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-lucino-goud-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-mirthe-goud-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kyan-goud-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-jaylen-light-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-yuna-groot-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-leah-klein-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-maevy-zwart-110o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-maesa-zwart-90o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-maeron-zwart-70o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-snake-fiona-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-dorine-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-marila-met-25-spiegels-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-madlen-met-veren-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-macynn-met-veren-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-maevy-goud-110o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-maesa-goud-90o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-maeron-goud-70o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-jeff-met-12-spiegels-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-birche-met-16-spiegels-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-isaiha-met-4-ronde-spiegels-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-jazzey-60o-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-cass-rond-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-morse-rond-54o-groot-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-montel-round-41o-klein-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-kensington-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jindy-off-white-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/coussin-jayda-40x60-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jaylinn-45x45-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jezz-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jenai-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-derial-vierkant-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-deonne-rond-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-danell-rond-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-willson-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-greyson-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-bryson-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-scott-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-westin-metaal-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-everson-metaal-vierkant-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-johnson-metal-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-silas-ivoor-goud-groot-ivoor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-silas-ivoor-goud-medium-ivoor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-silas-ivoor-goud-klein-ivoor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-timo-groot-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-xemm-brons-goud-groot-bronze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-xemm-brons-goud-medium-bronze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-xemm-brons-goud-klein-bronze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-chenna-goud-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-ross-medium-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-ross-klein-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-dixon-medium-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-maira-goud-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-majlen-goud-medium-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-magly-goud-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-raylan-zwart-goud-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-roann-zwart-goud-medium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-reen-zwart-goud-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/athene-eetkamerstoel-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-niles-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-orvil-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-jenyd-groot-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-jeral-klein-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-lyle-leopard-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaars-welton-set-of-2-types-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-morley-van-marmer-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-morton-van-marmer-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-teddy-pink-50x50-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-teddy-white-40x60-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-teddy-white-50x50-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-teddy-pink-40x60-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/monkey-deco-object-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dog-miro-deco-object-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/cheetah-deco-object-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/decoratieve-standaard-dilan-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/decoratieve-standaard-dilan-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/faux-koraal-efron-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/faux-koraal-aaron-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/decoratieve-standaard-alina-groot-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/decoratieve-standaard-alina-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kwal-djelly-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenstandaard-kala-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dog-graffity-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dog-miro-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dog-miro-pink-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/panther-yaro-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/gorilla-music-tonka-silver-finish-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dog-spike-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/gorilla-koko-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/faux-koraal-milan-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/faux-koraal-adam-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dienblad-blane-zwart-croco-set-van-2-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dienblad-carrell-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/foto-lijstje-jalou-snake-look-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/decoratie-box-turtle-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/juwelen-box-jaylyn-roze-goud-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/juwelen-box-blaze-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/juwelen-box-bayou-met-marmer-uitstraling-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/juwelen-box-charly-snake-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/juwelen-box-ruby-snake-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/juwelen-box-maeve-zwart-marmer-look-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/plantenbak-houder-elodie-set-van-2-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/faux-koraal-ayla-op-standaard-groot-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/faux-koraal-jesse-op-standaard-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/gorilla-deco-object-goud-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dog-deco-object-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/space-monkey-deco-object-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/krokodil-deco-object-medium-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/krokodil-deco-object-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lion-deco-object-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bizon-jaylee-goud-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-orion-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-kensington-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-hunter-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-cromford-mill-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-classio-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-chandon-met-plank-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-bloomingville-3-laden-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-vendome-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-tuxedo-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-cobra-30o-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-cobra-40o-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-chase-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-odin-80o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-honey-100o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-orlan-set-van-2-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-big-rich-set-van-2-brushed-gold-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-tetrad-set-of-4-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-jackson-795o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-ironville-90o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-ironville-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-industrial-kensington-set-van-2-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-kensington-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-kensington-set-van-2-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-hunter-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-cromford-mill-set-van-2-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-conrad-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-conrad-set-van-2-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-classio-100o-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-classio-150x80-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-dexter-77o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-volenta-set-van-3-champagne-gold-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-caldox-97o-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-catana-set-van-2-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-bolder-set-van-2-aluminium-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blax-110o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-silver-set-van-2-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-gold-set-van-2-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blackbone-brass-set-van-2-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bartafel-blackbone-gold-160-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-amelia-antraciet-gold-zzz-quartz-antraciet-801-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-emmy-425o-white-boucle-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-emmy-70o-khaki-velvet-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-emmy-70o-white-boucle-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-mayfair-black-velvet-brushed-gold-quartz-black-800-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-mayfair-white-teddy-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-teddy-white-teddy-bushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-kylie-antraciet-velvet-gold-zzz-quartz-antraciet-801-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-kylie-khaki-velvet-gold-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-inova-white-boucle-black-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-amelia-white-faux-sheep-black-faux-sheep-313-2-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-jago-khaki-velvet-brushed-gold-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-jago-stone-velvet-brushed-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-jago-white-boucle-brushed-gold-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-huxley-antraciet-velvet-brushed-gold-zzz-quartz-antraciet-801-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-huxley-khaki-velvet-brushed-gold-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-huxley-white-boucle-brushed-gold-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-richelle-white-boucle-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-linsey-draaibaar-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-bolton-khaki-velvet-gold-fire-retardant-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-imani-black-velvet-quartz-black-800-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-imani-green-velvet-zzz-quartz-green-501-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-imani-khaki-velvet-zzz-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-imani-stone-velvet-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-indigo-khaki-velvet-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-indigo-stone-velvet-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-indigo-white-boucle-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/counterstoel-pullitzer-white-boucle-black-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/counterstoel-xenia-white-boucle-brushed-gold-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-xenia-white-boucle-brushed-gold-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-chrissy-pu-leder-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-lucy-met-zwarte-poot-draaibaar-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-harper-zwart-croco-croco-velvet-black-hd007-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-harper-green-feather-feather-velvet-green-hd002-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-harper-white-boucle-brushed-gold-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kinderstoel-charly-white-teddy-gold-zzz-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-tabitha-quartz-khaki-alaska-stone-zzz-alaska-102-stone-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-tabitha-quartz-stone-emerald-stone-zzz-emerald-stone-107-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-giovanna-quartz-khaki-alaska-stone-zzz-alaska-102-stone-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-giovanna-quartz-stone-emerald-stone-zzz-emerald-stone-107-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-hadley-antraciet-velvet-brushed-gold-zzz-quartz-antraciet-801-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-hadley-khaki-velvet-brushed-gold-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-hadley-stone-velvet-brushed-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-indigo-stone-velvet-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-indigo-white-boucle-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-cannon-khaki-brushed-gold-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-cannon-stone-brushed-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-cannon-white-boucle-brushed-gold-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-fallon-white-boucle-black-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-odessa-white-boucle-brushed-gold-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-darby-white-boucle-black-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/trolley-hendricks-rond-goud-met-glas-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pilaar-josper-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-barkley-2-deuren-3-planken-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-ironville-2-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-hunter-2-laden-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-magnus-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-lagrand-gold-met-3-planken-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-cromford-mill-2-deuren-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-ironville-2-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-kensington-2-planken-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-hunter-2-laden-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-cromford-mill-4-deuren-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-blax-2-kleppen-1-plank-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-blackbone-brass-4-deuren-200-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/nachtkastje-ironville-2-laden-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ladenkast-classio-3-laden-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ladenkast-blackbone-silver-2-laden-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-oakura-2-deuren-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-ironville-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-kensington-3-laden-1-plank-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-kensington-2-deuren-3-laden-incl-glas-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-hunter-4-deuren-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-barkley-3-deuren-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-cromford-mill-4-deuren-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bureau-blackbone-brass-1-lade-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-blackbone-gold-4-deuren-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-blackbone-brass-4-deuren-open-vak-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenkast-oakura-2-deuren-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenkast-kensington-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenkast-blackbone-brass-2-deuren-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bureau-hunter-3-laden-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bureau-classio-2-laden-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/santos-decoratie-kussen-60x60-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-fratelli-90x69-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/nekrol-fratelli-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-odin-45o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-vector-40o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-jackson-525o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-lexington-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-ironville-50o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-kensington-45x45-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-classio-455o-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-lewis-set-van-2-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-chandon-set-van-2-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-blax-585o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-blackbone-brass-50x50-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-blackbone-brass-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/valentijn-bank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jens-bank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tapas-snacktafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/chenna-kandelaar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/cassia-kandelaar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/civan-kandelaar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pastille-lounge-xl",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/saar-barkruk",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/merry-barkruk",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/porto-barkruk",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/elio-barkruk",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/maira-kandelaar-goud-groot-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/raylan-kandelaar-zwart-met-goud-groot-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fishbone-eettafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/love-shaggy-rond",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-plush",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-galaxy",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/adele-fauteuil-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-spectrum",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ladenkast-blax-2-laden-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-blax-2-deuren-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/richmond-vloerkleed",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-royce",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/marcello-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/irwin-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/anneloes-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tube-salontafel-passepartout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamerstoel-de-lux",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/amsterdam-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-singapore",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenrek-wellington",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-jasmijn-copper-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-luxus-bronze-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-manifesto-ecru-stone-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-eleonora-leaves-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-eleonora-stone-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-abygale-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kaarshouder-anniek-antiek-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kaarshouder-elles-antiek-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-reynaldo-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-pixel-mink-hoog-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poef-pixel-mink-laag-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-opera-bruin-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-st-tropez-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-opera-bruin-strook",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-croc-ring-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-euphoria-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-croc-fawn-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-pixel-mink-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-babylon-strook-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-babylon-zwart-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-babylon-taupe-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sierkussen-anni-jacquard-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kayleigh-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suneko-tafellamp-o35x46-cm-antiek-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suneko-tafellamp-o25x34-cm-mat-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suneko-tafellamp-o35x46-cm-mat-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/xavio-hoekbank-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lexus-hoekbank-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-pilka-mat-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/table-lamp-duhle-silver-o31x101",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/floor-lamp-duhle-silver-o31x170",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandlamp-mylas-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandlamp-mylas-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-reeve-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-jalin-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-jaira-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-upanova-khaki-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-upanova-coral-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-rosalique-inclusief-lampenkap-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-nyo-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-mya-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-leila-inclusief-lampenkap-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-jazzlyn-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-jamero-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-jaina-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-jaina-goud-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-python-ovaal-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-python-cilinder-50%c2%9d-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-python-cilinder-30%c2%9d-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-philou-ovale-roze-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-philou-cilinder-50%c2%9d-roze-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-philou-cilinder-40%c2%9d-roze-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-marly-chelsea-zilver-richmond-interiors-7",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-marly-chelsea-zilver-richmond-interiors-6",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-marly-chelsea-zilver-richmond-interiors-5",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-marly-chelsea-zilver-richmond-interiors-4",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-marly-chelsea-zilver-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-jaylinn-velvet-taupe-richmond-interiors-10",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-jaylinn-velvet-taupe-richmond-interiors-9",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-jaylinn-velvet-taupe-richmond-interiors-8",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-jaylinn-velvet-taupe-richmond-interiors-7",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-jaylinn-velvet-taupe-richmond-interiors-6",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-goya-ovaal-goud-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-goya-cilinder-40%c2%9d-goud-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-emily-velvet-graphite-richmond-interiors-7",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-emily-velvet-graphite-richmond-interiors-6",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-quinty-goud-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-quinty-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-quinsy-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-mattiz-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-dayne-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-chanda-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-anisha-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-anisha-wit-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-milas-zwart-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-faey-zwart-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-levanto-met-plank-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-beaumont-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-blackbone-silver-2-deuren-hoog-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vitrine-cascade-2x2-deuren-metalen-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-shelena-goud-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-dylan-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-dylan-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-daveen-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-cilou-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-ceylin-klein-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-ceylin-groot-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-redmond-met-3-open-vakken-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-herringbone-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-beaumont-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-piper-green-velvet-fire-retardant-quartz-green-501-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-meadow-khaki-velvet-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-lizzy-white-boucl-gold-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-lizzy-white-boucl-black-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-emerson-jade-velvet-fire-retardant-genova-504-jade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-ella-steel-velvet-quartz-steel-806-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-ella-khaki-velvet-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-chiara-blush-velvet-genova-706-blush-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-chelsea-green-velvet-gold-quartz-green-501-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-angelica-white-boucl-fire-retardant-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-colleene-white-boucl-fire-retardant-fr-copenhagen-900-boucl-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-colleene-khaki-velvet-fire-retardant-fr-quartz-903-khaki-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-colleene-antraciet-velvet-fire-retardant-fr-quartz-801-antraciet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-ceyla-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-carlo-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-beaumont-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-steel-smith-zwart-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-lanson-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-steel-smith-zwart-set-van-2-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-redmond-130x80-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-lio-set-van-3-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-jude-set-van-2-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-dalton-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-beaumont-set-van-2-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hoektafel-redmond-55x55-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/trolley-bombay-stone-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-tetrad-4-deuren-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/schuifdeurkast-2x2-deuren-2-laden-ral9010-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/schuifdeurkast-2x2-deuren-2-laden-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/buffetkast-oakdale-2x4-deurs-4-laden-ral9010-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/buffetkast-knox-2x2-deuren-metalen-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-branda-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-branda-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-amaya-groot-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/rocky-chair-maddy-white-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-felicia-pink-velvet-gold-quartz-pink-700-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-teddy-white-furry-himalaya-900-white-furry-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-bristel-pink-velvet-rib-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-bristel-navy-velvet-rib-night-blue-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uitschuiftafel-blackbone-silver-195265-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uitschuiftafel-blackbone-gold-195265-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-redmond-200x100-met-kruispoten-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-herringbone-240-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-herringbone-200-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-dalton-230-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-catana-140%c2%9d-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-bloomingville-230-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-bloomingville-200-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-silver-220-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-silver-180-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-silver-140%c2%9d-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-matrix-gold-200-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-gold-220-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-gold-180-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-gold-140%c2%9d-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-230-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-200-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-beaumont-230-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-beaumont-200-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-baccarat-230-dark-coffee-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-baccarat-200-dark-coffee-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-redmond-4-deuren-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-herringbone-4-deuren-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-2-schuifdeuren-2-laden-ral9010-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-2-schuifdeuren-2-laden-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenkast-oakdale-3-laden-3-planken-ral9010-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-steel-smith-brass-%c2%9d50-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-levi-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-ledger-445%c2%9d-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-lanson-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-dalton-40%c2%9d-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-beaumont-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-barret-set-van-2-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bartafel-lando-rond-60%c2%9d-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bartafel-blackbone-silver-160-black-rustic-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-tulip-ochre-velvet-quartz-oaker-402-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-waylon-pink-velvet-rib-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-cosette-3-zits-stone-velvet-gold-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-cosette-3-zits-pink-velvet-gold-quartz-pink-700-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-bourbon-met-2-kussens-pink-velvet-gold-quartz-pink-700-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/opberg-box-vernell-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/palmboom-kandelaar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bram-kandelaar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ghani-vloerlamp-o58x184-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ronin-vloerlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/nicolo-vloerlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/xibor-schaal-4",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/xibor-schaal-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/xibor-schaal-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/xibor-schaal",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-odion-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-odion",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-kamudi",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-head-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-head-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-head",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-face-4",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-face-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-face-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-face",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-mashaba-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-mashaba-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-mashaba",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kavandu-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kavandu-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kavandu",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandornament-duaca",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-jenna",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10230",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10218",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10211",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-astor-groot-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-albion-groot-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-albion-medium-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-albion-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-ebrill-goud-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-elwyn-goud-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-barlow-op-standaard-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-osmin-zwart-goud-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-bartley-goud-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kenza-medium-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-kenza-klein-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-aletha-groot-wit-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-alesha-wit-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-carlos-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-carlo-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-coley-met-15-ronde-spiegels-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jorsy-gold-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jowi-pink-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jason-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jill-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jesper-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jasmine-40x60-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jenthel-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-jaxi-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bloem-dahlia-taupe-12-stuks-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/gras-palm-honey-8-stuks-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/gras-pampas-pink-groot-6-stuks-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bloem-dahlia-honey-12-stuks-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bloem-dahlia-rosee-12-stuks-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bloem-rose-rosee-white-18-stuks-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bloem-rose-lilac-24-stuks-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-bradon-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/klok-owen-vierkant-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-bray-groot-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-clay-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-carlee-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/plantenbak-houder-jalyce-set-van-2-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/decoratie-paard-blindjai-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-jeremy-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-teddy-zwart-40x60-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kussen-teddy-zwart-50x50-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dienblad-cady-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/krokodil-deco-object-groot-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/snake-deco-object-medium-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/snake-deco-object-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/juwelen-box-penny-snake-set-van-2-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/faux-koraal-jada-op-standaard-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/faux-koraal-aryan-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-emily-velvet-graphite-richmond-interiors-5",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-emily-velvet-graphite-richmond-interiors-4",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lampenkap-emily-velvet-graphite-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-burlesque-khaki-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-burlesque-roze-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-adney-zwart-nickel-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-bliss-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/plafondlamp-cailey-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-mavey-goud-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-mavey-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-amira-goud-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-amira-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-jenna-goud-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-ziggy-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-daria-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandlamp-davine-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandlamp-collin-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandlamp-cassedy-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandlamp-destiny-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandlamp-daine-incl-lampenkap-zwart-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandlamp-feline-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-alicia-goud-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-alicia-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-elvina-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-upanova-roze-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-upanova-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-burlesque-wit-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-upanova-wit-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-dyon-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-jasey-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-felyn-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-annson-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-averil-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-colada-ananas-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-kayla-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-bobbie-zwart-nickel-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tafellamp-byron-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-adyson-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-cyrine-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-blaze-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-naila-met-14-kandelaren-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-benzo-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-beryl-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-yale-klein-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-crosley-met-8-verschillende-lampen-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-riley-g9-fitting-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-bryon-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-camdon-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-yara-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-industrial-kensington-140x40-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandtafel-conrad-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/sofa-tafel-steel-smith-brass-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-steel-smith-brass-set-van-2-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-colby-39o-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-coney-25o-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-candy-roze-35o-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-trinity-roze-grijs-set-van-3-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-candy-pink-77o-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-chester-set-van-3-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-penta-zilver-set-van-3-zeshoek-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-penta-goud-set-van-3-zeshoek-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-tree-set-van-3-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-quatro-set-of-4-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-delia-80o-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-degas-80o-zzz-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-lexington-set-van-2-zzz-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-lexington-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-lexington-90o-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-dalton-set-van-2-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-dalton-set-van-2-draaibaar-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-dalton-90o-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-lustrio-92o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-vendome-140o-incl-glas-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-tuxedo-200-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-tuxedo-230-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-odin-140o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poot-industrial-spider-155x100-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poot-industrial-spider-155x100-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-raffles-200-gerecyceld-hout-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-raffles-240-gerecyceld-hout-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-bodhi-140o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-bodhi-160o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-poot-x-leg-bodhi-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-poot-x-leg-bodhi-zwart-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/poot-industrial-oakura-spider-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-oakura-230-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-oakura-200-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/blad-old-oak-normandy-180x100-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/blad-old-oak-normandy-200x100-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/blad-old-oak-normandy-240x100-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/set-poten-castle-oil-grey-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/set-poten-castello-ral9010-ral9010-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-nalo-180-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-nalo-200-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-nalo-220-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-nalo-235-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-nalo-ovaal-235-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-watson-230-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-watson-o140-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-watson-ovaal-235-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-watson-ovaal-300-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-grandis-200-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-grandis-230-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-top-grandis-300-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafelpoten-set-u-poot-zilver-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafelpoten-set-u-poot-goud-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafelpoten-set-u-poot-brushed-gold-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-can-roca-square-black-70x70-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-osteria-80o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-delia-130o-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-degas-120o-zzz-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-tenille-130o-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-lexington-130o-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-lexington-200-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-lexington-230-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-lexington-ovaal-230-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-levanto-kruispoot-200-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-levanto-kruispoot-240-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-kensington-200-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-kensington-240-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-kensington-180-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafelpoten-set-kensington-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-hunter-190-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-hunter-220-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-hunter-260-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-hunter-230-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-dalton-130o-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-cromford-mill-190-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-cromford-mill-230-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-conrad-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafelpoot-matrix-goud-conrad-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blax-ovaal-230-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blax-200-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blax-230-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blax-ovaal-250-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-silver-140o-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-silver-180-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-silver-220-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-gold-220-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-gold-140o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-gold-180-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-brass-ovaal-230-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blackbone-brass-220-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bartafel-raffles-140-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bartafel-blackbone-silver-160-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-emily-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-emily-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-jessy-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-jessy-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-mayfair-pink-velvet-gold-zzz-quartz-pink-700-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-mayfair-stone-velvet-brushed-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-teddy-black-teddy-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-balou-black-velvet-gold-quartz-black-800-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-kylie-stone-velvet-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-inova-blush-velvet-black-zzz-genova-706-blush-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-inova-jade-velvet-black-zzz-genova-504-jade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-amelia-antraciet-gold-zzz-quartz-antraciet-801-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-fayah-sand-velvet-brushed-gold-genova-904-sand-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-fayah-white-teddy-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-letitia-white-boucle-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/draaifauteuil-bristel-nougat-velvet-rib-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-daisy-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-daisy-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-day-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-day-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-diana-zwarte-poten-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-diana-zwarte-poten-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-bolton-pink-velvet-gold-fire-retardant-zzz-quartz-pink-700-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-bolton-stone-velvet-silver-fire-retardant-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-bolton-stone-velvet-gold-fire-retardant-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-linsey-draaibaar-green-velvet-quartz-green-501-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-brandy-pink-velvet-goud-zzz-quartz-pink-700-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-angelica-stone-velvet-fire-retardant-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-pippa-khaki-velvet-gold-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-pippa-pink-velvet-gold-zzz-quartz-pink-700-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-pippa-stone-velvet-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-tulip-antraciet-velvet-zzz-quartz-antraciet-801-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-tulip-khaki-velvet-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/counterstoel-pullitzer-jade-velvet-black-zzz-genova-504-jade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/counterstoel-pullitzer-khaki-velvet-black-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/counterstoel-xenia-blush-velvet-brushed-gold-genova-706-blush-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/counterstoel-xenia-jade-velvet-brushed-gold-genova-504-jade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-xenia-blush-velvet-brushed-gold-genova-706-blush-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barstoel-xenia-jade-velvet-brushed-gold-genova-504-jade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-ivy-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-ivy-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-macy-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-macy-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-james-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-james-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-chelsea-khaki-velvet-silver-zzz-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-chelsea-stone-velvet-silver-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-pippa-zwart-velvet-gold-quartz-black-800-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-harper-stone-feather-feather-velvet-stone-hd001-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-maxwell-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-maxwell-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-julius-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-julius-khaki-velvet-gold-footcap-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-julius-stone-velvet-gold-footcap-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-julius-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-chrissy-green-velvet-quartz-green-501-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-hoekbank-ave-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-hoekbank-ave-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-ave-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-ave-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-hoekbank-blaire-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-hoekbank-blaire-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-blaire-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-blaire-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-hoekbank-cleo-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-hoekbank-cleo-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-cleo-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-cleo-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-moris-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-moris-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-angelica-stone-velvet-fire-retardant-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-tabitha-genova-ash-emerald-dark-grey-emerald-805-dark-grey-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-tabitha-genova-ochre-emerald-ochre-zzz-emerald-400-ochre-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-giovanna-genova-ochre-emerald-ochre-zzz-emerald-400-ochre-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-farah-stone-velvet-brushed-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-lewis-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-lewis-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-lewis-stone-velvet-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-morgan-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-morgan-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-indigo-khaki-velvet-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-felicia-khaki-velvet-gold-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-fallon-blush-velvet-black-zzz-genova-706-blush-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-fallon-gold-velvet-black-zzz-genova-401-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-fallon-jade-velvet-black-zzz-genova-504-jade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-odessa-stone-brushed-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-sierra-blush-velvet-draaibaar-zzz-genova-706-blush-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-sierra-jade-velvet-draaibaar-zzz-genova-504-jade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-sierra-sand-velvet-draaibaar-genova-904-sand-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-tresanto-stone-velvet-silver-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-angelica-white-boucle-fire-retardant-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stoel-lizzy-white-boucle-black-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pilaar-lexington-set-van-2-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pilaar-kensington-set-of-2-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pilaar-dalton-set-van-2-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/pilaar-bolder-set-van-2-aluminium-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-lagrand-black-met-3-planken-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/top-connection-oakura-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/linnenkast-blackbone-silver-2-deuren-2-laden-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/linnenkast-blackbone-gold-2-deuren-2-laden-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ladenkast-kensington-5-laden-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-oakdale-4-deurs-4-laden-ral9010-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-blackbone-silver-4-deuren-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bureau-blackbone-silver-1-deur-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bureau-blackbone-gold-1-deur-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bureau-dalton-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/buffetkast-oakdale-2x3-deuren-3-laden-ral9010-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenkast-vendome-4-planken-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/boekenkast-oakura-2-deuren-smal-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-hoekbank-delilah-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-hoekbank-delilah-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-delilah-fire-retardant-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eetkamer-bank-delilah-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-waylon-nougat-velvet-rib-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-santos-3-zits-arm-links-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-santos-lounge-95-rechts-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-vector-45o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-degas-50o-zzz-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-lexington-set-van-2-zzz-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-lexington-40o-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-kensington-60x60-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-dalton-set-van-2-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-lohan-set-van-2-gold-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-booker-32o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-dexter-38o-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-arden-champagne-gold-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-bolder-aluminium-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-bolder-set-van-2-aluminium-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-lustrio-48o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bijzettafel-blax-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-santos-3-zits-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-roxanne-35-zits-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-rebel-stone-velvet-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-rebel-khaki-velvet-gold-zzz-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-juniper-3-zits-lounge-links-rechts-white-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-juniper-3-zits-lounge-links-rechts-stone-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-juniper-3-zits-lounge-links-rechts-stone-zzz-emerald-stone-107-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-huxley-white-boucle-brushed-gold-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-huxley-khaki-velvet-brushed-gold-quartz-khaki-903-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-huxley-antraciet-velvet-brushed-gold-zzz-quartz-antraciet-801-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-fratelli-hoekelement-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-fratelli-35-zits-arm-rechts-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-fratelli-35-zits-arm-links-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-fratelli-3-zits-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-devon-ottoman-links-open-eind-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-devon-hoekelement-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-devon-15-zits-zonder-armen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-devon-15-zits-arm-rechts-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-bourbon-met-2-kussens-white-gold-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bank-bourbon-met-2-kussens-stone-velvet-gold-zzz-quartz-stone-101-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/santos-bank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lesane-kandelaar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/struisvogel-wandlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/arturo-wandlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tubby-wandlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/twilight-wandlicht",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/troy-eettafel-met-verjonge-rand",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/nerino-eettafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/catho-kandelaar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ted-sparks-room-spray-fresh-linen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ted-sparks-hand-soap-fresh-linen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ted-sparks-geurkaars-fresh-linen-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ted-sparks-geurkaars-demi-fresh-linen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stylo-vloerlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/leah-vloerlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerlamp-jade",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bhilai-tafellamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/flight-tafellamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/isa-windlicht",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/stelvio-hanglamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/limone-hanglamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/terra-hanglamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/riva-hanglamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salerno-hanglamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/catania-lamp-ovaal",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/gasim-ornament-op-voet",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lasim-ornament-op-voet",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/gouya-ornament-wit-agaatantiek-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/outdoor-candle-nicky",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/outdoor-candle-lou",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kaarsenhouder-jip",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-cas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-evi",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-malo",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/houten-ornament",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-perroy",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-diozy",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-giry",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-deoni",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-givrin",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-korali",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-livon",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vaas-georgina",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/macau-bijzettafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tomochi-eettafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-parata",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/theelicht-osana",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/skoop-windlicht",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/windlicht-phil",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/adeta-windlicht",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/racco-lampvoet",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/omega-tafellamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/liva-tafellamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/deco-led-globe-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/deco-led-globe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/deco-led-hoekig",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/hanglamp-neyla",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tarida-hanglamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/moroc-hanglamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/rakel-hanglamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/rakel-vloerlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dennis-vloerlam",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lekar-vloerlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-parina",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/towa-spiegel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-gouya-zwart-agaatantiek-brons",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wijnrek-malbeo",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-olumi",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/etagere-lorena",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/zinc-lampenkap",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dark-linen-lampenkap",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jacinto-tafellamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-luxor",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-veranzo",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-naru",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-cleveland",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-lana",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandornament-clavinas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-djebbe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/benzo-vaas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suar-boomstamtafel-260x94-124x10-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suar-boomstamtafel-370x120-140x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suar-boomstamtafel-260x100-120x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suar-boomstamtafel-350x105-83x10-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suar-boomstamtafel-260x110x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suar-boomstamtafel-300x100x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suar-boomstamtafel-270x100x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suar-boomstamtafel-300x95-100x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/suar-boomstamtafel-310x120x9-xm-x10150",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-wijnrek-uit-indonesie",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-op-voet-o25x38-cm-waiwo",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-op-voet-o25x38-cm-casim",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ornament-op-voet-o35x56-cm-rawas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/levi-hoekbank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/cole-hoekbank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lezay-vaas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/bureau-blax-2-laden-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-blax-130x80-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kist-india-x10146",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dressoir-blax-2-schuifdeuren-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/india-dressoir-2-deuren-x10106",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-dressoir-china-x10103",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-dressoir-china-3-deuren-x10111",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-india-dressoir-met-4-deuren-x10114",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-india-dressoir-x10119",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/india-dressoir-met-2-deuren-x10137",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-india-dressoir-x10141",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-india-dressoir-x10144",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-india-dressoir-x10145",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-dressoir-china-x10093",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eettafel-blax-rond-o140-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/india-boekenkast-x10105",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kast-china-2-deuren-x10100",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-india-x10121",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-boekenkast-x10126",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10128",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10129",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-india-kastje-x10130",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10133",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/india-nachtkastje-met-1-deur-x10138",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-india-kast-x10143",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-x10085",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/china-sidetable-groen-x10118",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/cavera-bank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jack-tv-meubel-200-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jack-tv-meubel-160-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-tv-meubel-china-x10084",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/uniek-tv-meubel-china-x10089",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/cubicle-tv-dressoir-180-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/cubicle-tv-dressoir",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-kast-china-x10099",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/unieke-sidetable-china-x10079",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/rio-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/aiden-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/eduardo-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/else-bank-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-jordan",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-iris",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/honolulu-salontafel-set",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/salontafel-india-10",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-stine",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vloerkleed-robin",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jamiri-tafellamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/vitora-tafellamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/armata-vloerlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/spiegel-ispilu-mat-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kandelaar-ziya-mat-zwart",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/wandkast-redmond-maddox",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jack-tv-meubel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jack-sidetable",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jack-eettafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/kayden-bank-kotter-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jack-dressoir-met-4-deuren",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jack-dressoir",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jack-boekenkast-met-2-deuren",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/jack-buffetkast",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/evi-badkamerkast",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/dex-badkamerkast",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/adeta-vloerlamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/brandon-hanglamp",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/baha-ronde-bijzettafel-op-wielen-40-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/baha-ronde-bijzettafel-op-wielen-60-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/baha-eettafel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/amalia-eetkamerstoel-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/ariane-eetkamerstoel-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/silvilla-eetkamerstoel-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/tv-dressoir-rill",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/fauteuil-burlington-polar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/lara-eetkamerbank-van-het-anker",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/alhambra-relaxfauteuil",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/barcelona-eetkamerstoel-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/apeldoorn-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/joyce-eetkamerstoel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/rozali-draaifauteuil",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/product/reflex-fauteuil",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/marken",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/marken/bocx-interiors-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/marken/eleonora-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/marken/richmond-interiors-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/marken/room108-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/badmoebel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/badmoebel/badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/badmoebel/waschtischunterschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/sofas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/sofas/ecksofas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/sofas/couchgarnituren",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/stuehle/sessel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/stuehle/sessel/sessel-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/stuehle/relaxsessel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/schraenke",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/schraenke/buecherschraenke",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/schraenke/landhausschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/schraenke/sideboards",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/schraenke/schrank-nach-mass",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/schraenke/kleinmobel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/schraenke/highboard",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/schraenke/tv-lowboard",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/stuehle",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/stuehle/barhocker",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/stuehle/barstuehle",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/stuehle/esszimmerbaenke",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/tische",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/tische/bartische",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/tische/beistelltische",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/tische/esstische",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/tische/wohnzimmertische",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/tische/wandtische",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/wohnaccessoires",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/hocker",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/kissen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/leuchten",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/teppiche",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/wanddeko",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/spiegel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/marken/passe-partout-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/koetter-kollektion",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/outlet-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/saulen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/marken/ted-sparks-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/geurartikelen-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/marken/nix-design-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/marken/h-e-design-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/tische/wohnzimmertische/versteinertes-holz",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/moebel/tische/baumstammtische",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/kaarsenhouder-de",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/ted-sparks-de-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/vasen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/meubel/wohndeko/uhren",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/shop",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/einkaufswagen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/zur-kasse",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/mein-account",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/koetter-kollektion",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/inspiration/basic",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/inspiration/laendlich",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/inspiration/modern",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/zimmer",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/zimmer/esszimmer",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/zimmer/flur",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/zimmer/schlafzimmer",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/zimmer/wohnzimmer",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/geschaeftsbedingungen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/datenschutzerklaerung",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/impressum",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/das-ist-koetter-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/geschichte",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/cbw-zugelassenes-geschaeft",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/gratis-3d-einrichtungsentwurf",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/haeufig-gestellte-fragen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/kontakt/bestaetigung-der-newsletter-registrierung",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/kontakt/vielen-dank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-lisette-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lewis-esszimmertisch-aus-re",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-trebbia-easy-sofa",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmertisch-joshua",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmertisch-joshua-massiv",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-caitlin-altholz",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmertisch-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-baha-auf-radern-60-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-baha-auf-radern-40-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-kiel-2er-set",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-avelino-aus-railway-holz",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/avelino-tv-schrank-180-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-avelino-4-turen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-avelino-2-turen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmertisch-avelino",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-mia-3er-set",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-goa-3er-set",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-julia-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-leona-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-isabelle-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-clarisse-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-lucia-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-juliette-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-aymee-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecksofa-eva-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-christiane-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-elise-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerstuhl-ferro-mit-armlehne-tower-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerstuhl-ferro-ohne-armlehne-tower-living",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-christina-room",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-gigi-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-annabelle-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-sophie-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-victoria-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-michelle",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-elena-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-noelle-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-diane",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-joelle-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-carmen-room-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerstuhl-lyon-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ss",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-hetty-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-helsinki-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-hamilton-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-george-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-garlton-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-florian-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-cornwall-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-carcia-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-canton-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-berlin-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-amsterdam-bocx-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerstuhl-lara-ohne-armlehne-het-anker",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerbank-fenna-het-anker",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-aspen-het-anker",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-set-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-marmor-50x50-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-industriell-2-turen-1-schublade-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-industriell-3-schubladen-1-tur-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bucherregal-industriell-2-turen-und-2-schubladen-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-toronto-300x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-toronto-240x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-toronto-210x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sidetable-toronto-140x40-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-vintage-2-turen-und-3-schubladen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-hudson-240x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-hudson-200x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-hudson-160x90-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-hudson-240x100-cm-braun-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-hudson-200x100-cm-schwarz-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-hudson-160x90-cm-schwarz-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-hudson-3-turen-und-2-schubladen-schwarz-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bucherregal-hudson-1-schublade-braun-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bucherregal-hudson-1-schublade-schwarz-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-hudson-2-turen-und-2-schubladen-braun-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-hudson-2-turen-und-2-schubladen-schwarz-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-hudson-2-turen-und-2-schubladen-braun-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartige-versteinerte-holztische",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-hudson-2-schubladen-150cm-braun-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-hudson-2-schubladen-185cm-braun-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-hudson-2-schubladen-185cm-schwarz-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-hudson-braun-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sideboard",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/teppich-singapore",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sideboard-calloway-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sideboard-blackbone-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sideboard-blackbone-silber-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sideboard-dynasty",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sideboard-oakura-180-x-40-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sideboard-oakura-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-raffles-2-turen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-blackbone-gold-2-turen-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/badezimmermobel-gijs",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-hudson-schwarz-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-raffles-recyceltes-holz-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-raffles-4-turen-recyceltes-holz-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deckenleuchte-tanya-braun-gold-o41x74-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deckenleuchte-reilley-matt-schwarz",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deckenlampe-djem-holz-o60x60-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-sianna",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/zico-ecksofa-passe-partout",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deckenlampe-adrienne-schwarz",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tischlampe-laterne-gruaro",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/django-teak-badezimmermobel-hangend",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ivan-teak-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/leon-teak-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tara-teak-badezimmerschrank-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tara-teak-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sunshine-teak-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/collin-teak-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/chris-teak-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/holly-teak-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/oman-teak-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/willy-teak-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-blackbone-gold-220-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-blackbone-gold-185-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-blackbone-silber-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-blackbone-silber-richmond-interiors-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-oakura-3-schubladen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-clara-stone-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-clara-khaki-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-raffles-recyceltes-holz-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofatisch-calloway-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-calloway-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-calloway-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/3er-set-couchtisch-vittorio-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/2er-set-finn-champion-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tisch-milo-aluminium-champion-gold",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tisch-lyam-aluminium-silber",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/2er-set-tisch-asher-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/3er-set-tisch-nolan-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/2er-set-tisch-brooke-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-blackbone-90x90-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-blackbone-150x80-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-blackbone-160x40-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-blackbone-90x90-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-blackbone-50x50-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-blackbone-160x40-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-blackbone-90x90-richmond-interiors-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-blackbone-50x50-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-blackbone-90x90-richmond-interiors-4",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-blackbone-150x80-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/rona-esszimmerstuhl",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/apeldoorn-esszimmerstuhl",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deckenleuchte-marley-matt-schwarz-bronze",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-levanto-2er-set-rund-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-dynasty-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-dynasty-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-orion-mit-braunem-marmor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-orion-80o-brauner-marmor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-orion-60o-brauner-marmor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-oakura-140x65-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-oakura-block-h-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecktisch-oakura-60x60-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-oakura-120x60-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-oakura-block-c-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-raffles-recyceltes-holz-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ecktisch-raffles-recyceltes-holz-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lara-esszimmerbank-von-het-anker",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerstuhl-lara-ohne-armlehne-het-anker-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/michigan",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-mona-mit-marmor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-lacey-2er-set-marmor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-oakura-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-paramount-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-gordon-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/nachtschrankchen-blackbone-gold-1-schublade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-blackbone-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-blackbone-silber-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/nachtschrankchen-blackbone-silber-1-schublade-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/trolley-x-o-gold-und-glas-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kindersessel-charly-pink-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/drehsessel-jamie-stone-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/drehsessel-jamie-pink-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/drehsessel-mila-stone-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/drehsessel-mila-rust-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/drehstuhl-mila-pink-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/drehstuhl-mila-green-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-indy-richmond-interiors-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-indy-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sessel-perla-stone-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sessel-perla-rust-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sessel-perla-pink-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sessel-perla-khaki-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sessel-perla-green-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sessel-emily-khaki-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bartisch-raffles-190x80-recyceltes-holz-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-kensington-rund-140o-incl-glas-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-dynasty-200-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-dynasty-rund-130o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-osteria-square-70x70-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-osteria-rond-80o-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-scarlett-black-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-scarlett-green-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-scarlett-khaki-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-scarlett-pink-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-scarlett-stone-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-pippa-stone-velvet-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-pippa-pink-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-pippa-khaki-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-pippa-green-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-pippa-stone-velvet-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-madison-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/buffetschrank-oakura-2x2-turen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/buffetschrank-oakdale-2x2-turen-2-schubladen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-raffles-4-turen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-blackbone-gold-4-turen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-blackbone-silber-4-turen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-oakura-4-turen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-oakura-3-turen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/buffetschrank-2x2-turen-2-schubladen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bucherschrank-oakura-2-turen-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/barstuhl-linsey-drehbar-stone-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/barstuhl-linsey-drehbar-khaki-velvet-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-harvey-geweih",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-shane-glas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-caleb-big",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-zayn-small",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-ian-big",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-noor",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lanterne-shelly",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-muna-silber-40-40",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-marly-chelsea-silber",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-marly-chelsea-silver",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-emily-velvet-graphite",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-emily-velvet-graphite-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-emily-velvet-graphite-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stehlampe-lenny-incl-black-shade",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tischlampe-lincoln-incl-weiser-schirm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hangelampe-reece",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hangelampe-ashton",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hangelampe-phyllon",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hangelampe-flore",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hangelampe-senn",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hangelampe-novee",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tischlampe-lois-inkl-schwarzem-lampenschirm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tischlampe-aria-inkl-black-shade",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-evi-with-4-candle-holders-inside-big",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/uhr-willson",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/pineapple-publishr-pina-small-silver",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/saule-raffles-2er-set",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/trolley-hennesy-glas",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/saule",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-felix",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-jord-small",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bildflamingo",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bild-skye",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bild-sabia",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-xavier-big",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-emmy-silber",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-joni-silver",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-bresso-silver-big-foot",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-ariana-taupe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-ariana-taupe-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-solange-black",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampshade-solange-schwarz",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-solange-schwarz",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-solange-schwarz-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-pink",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/uhr-north",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hocker-mara-ceramic-silver",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/decke-evelyn-brown",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/decke-amelia-grey",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blumentopfhalter-ravi",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenstander-bodi",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-amy-iceblue",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-amy-iceblue-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-amy-iceblue-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-amy-iceblue-4",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-pink-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-pink-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-demi-white",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-demi-white-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-demi-white-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampe-ibiza",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-opera-crystal-round-small",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deckenleuchte-safa",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deckenleuchte-nic",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tablett-koda-aluminium",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deckenleuchte-emin-incl-gold-shades",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blumentopfhalter-boaz",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-syl-kupfer-klein",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blumentopf-lauren",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/yoga-hund-yin-silver",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/yoga-hund-yang-light-gold",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kuh-schadel-chloe-alu",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/dekoration-nolan",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-soho-200x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-soho-200x100-cm-eleonora-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-soho-160x90-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-soho-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-soho-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-soho-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-locker-2-turen-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-locker-2-turen-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-locker-1-tur-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerbank-mango-230x40-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerbank-mango-180x40-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerbank-mango-140x40-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-mango-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-mango-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bucherregal-mango-160-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bucherregal-mango-94-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/beistelltisch-mango-90-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bartisch-mango-110-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-mango-200-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-mango-150-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-mango-240x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-mango-300x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-mango-200x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-mango-160x90-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-mango-140x80-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-mango-100x100-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-mango-1tur-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-mango-mit-metallbeinen-150-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-mango-mit-metallbeinen-130-cm-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/fernsehtisch-mango-1-tur-und-1-schublade-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/fernsehtisch-mango-2-turen-und-1-schublade-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-mango-3-turen-und-3-schubladen-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-geneve-3-turen-und-3-schubladen-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kabinet-geneve-1-tuer-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vitrine-geneve-2-turen-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kabinet-geneve-180-cm-2-tueren-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kabinet-geneve-220-cm-2-turen-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/fernsehtisch-geneve-3-deurs-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-barbara-motown-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-barbara-rosa-motown-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-barbara-grun-motown-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-barbara-blau-motown-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-menno-cognac-cowboy-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhlmenno-antraciet-cowboy-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-menno-braun-cowboy-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stuhl-daan-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-schrank-kensington-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/san-remo-ecksofa",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bloomingville-salontafel-set-van-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bucherregal-wellington",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/unieke-deur-met-spiegel-x10075",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/unieke-kast-india-x10074",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/unieke-india-kast-x10073",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/unieke-kast-uit-india-x10070",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/honolulu-salontafel-set",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/evi-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/dex-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-10061",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10056",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10055",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10062",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10064",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10054",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10053",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10052",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10051",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10050",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10049",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10047",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10046",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10045",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10044",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10043",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10042",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10065",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10041",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10040",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-x10039",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-indien-38",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hangelampe-brandon",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/xavi-wandschrank-typ-xx",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/xavi-couchtisch-typ-xix",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andrew-ovaler-esstisch-mit-geraden-seiten",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andrew-ovaler-esstisch-mit-schrager-seite",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/liam-runder-esstisch",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andreas-esstisch",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/love-shaggy-teppich",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wandtisch-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-sideboard-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-baha-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-baha-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tv-sideboard-baha-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wandtisch-baha-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/nachttisch-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/nachttisch-baha-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sideboard-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kommode-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schrank-baha-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sofa-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/baha-wandtisch",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/baha-esstisch-oval",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esstisch-baha-rund",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-baha-oval",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stehtisch-baha",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wandtisch-baha-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/couchtisch-jordan",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andreas-sideboard",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andreas-tv-mobel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andreas-schrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andreas-tv-mobel-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andreas-couchtisch",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andreas-couchtisch-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andreas-sideboard-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/andreas-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-china-x10083",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-china-x10098",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-china-x10096",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiger-schrank-aus-china-x10101",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartige-tv-mobel-aus-china-x10089",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-250x100-110x8-cm-x10149",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-310x120x90-cm-x10150",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-265x98-108x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-315x120x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-322x98-108x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-255x85-114x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-250x79-128x10-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-320x98-110x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-308x110-120x10-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-310x96-110x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-317x100-120x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-270x86-117x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-300x95-100x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-310x110x8-5-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-270x100x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-250x1009-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-300x100x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einzigartiges-weinregal-aus-indonesien",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-380x110x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-250x100x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-260x110x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-250x75-89x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-epoxy-320x120-140x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-402x120-110x10-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-430x125-90x10-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-350x105-83x10-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-335x120-140x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-260x100-120x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-300x115-140x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-300x100x9-cm-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-300x100x9-cm-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-250x97x7-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-370x120-140x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-260x94-124x10-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-260x120x8-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-309x110-120x10-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/suar-baumstammtisch-260x120x9-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/creed-couchtisch-aus-versteinertem-holz",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-aus-versteinertem-holz-12",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-2-aus-versteinertem-holz-41-42",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-aus-versteinertem-holz-40",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-aus-versteinertem-holz-43",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-aus-versteinertem-holz-39",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-aus-versteinertem-holz-38",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-aus-versteinertem-holz-37",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-aus-versteinertem-holz-36",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-2-aus-versteinertem-holz-32-33",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-2-aus-versteinertem-holz-34-35",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-2-aus-versteinertem-holz-20-21",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-29-30-31",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-2-aus-versteinertem-holz-25-26",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-17-18-19",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-2-aus-versteinertem-holz-15-16",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-2-aus-versteinertem-holz-10-11",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-2-aus-versteinertem-holz-7-9",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-4-5-6",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-1-2-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-266",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-270",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-269",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-268",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-beige-mix-267",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-beige-mix-265",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-264",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-263",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-262",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-261",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-260",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-259",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-258",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-257",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-256",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-255",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-254",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-253",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-252",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-251",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-250",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-249",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-248",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-247",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-246",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-245",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-244",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-243",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-242",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-241",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-240",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-239",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-238",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-237",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-236",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-235",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-234",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-233",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-232",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-231",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-230",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-229",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gewebtest-tuch-tibetan-child",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/esszimmerstuhl-charly",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hangelampe-rakel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-228",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-227",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-226",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-225",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-224",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-223",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-222",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-221",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-220",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-219",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-218",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-217",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-216",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-215",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-214",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-213",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-212",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-211",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-210",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-209",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-208",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-207",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-206",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-205",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-204",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-203",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-202",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-weiss-mix-201",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-120",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-119",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-118",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-117",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-116",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-115",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-114",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-113",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-112",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-111",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-110",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-109",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-107",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-106",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-105",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-104",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-103",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-102",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/emma-couchtisch-set-von-3-aus-versteinertem-holz-schwarz-101",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/adele-sessel-room108",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/forrest-sideboard",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/forrest-couchtisch",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/forrest-lagerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/forrest-fernsehschrank-180-cm",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sessel-daan-eleonora",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sydney-barhocker",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/dax-badezimmerschrank",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/envira-saule",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/haengelampe-tarida",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-jip",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/limone-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/staletti-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/rosello-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/mello-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/cassano-stehlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stresa-stehlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/oslo-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sermione-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/terra-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bryggen-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/riva-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ancona-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tortona-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/salerno-haengelampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/catania-haengelampe-oval",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/flight-tischlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bhilai-tischlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/arturo-wandlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stehlampe-jade",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/leah-stehlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/stylo-stehlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tubby-wandlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/haiko-wandlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/twilight-wandlampe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sessel-burlington-polar",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/levi-kerzenstaender",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/malo-lantaarn",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-duftkerze-magnum-bamboo-peony",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-duftkerze-double-magnum-bamboo-peony",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-handseife-bamboo-peony",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-geschenkset-bamboo-peony",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-raum-spray-bamboo-peony",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-duftkerze-demi-fresh-linen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-duftkerze-fresh-linen-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-geschenkset-fresh-linen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-handseife-fresh-linen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/ted-sparks-raum-spray-fresh-linen",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lesane-kersnhalter",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-djebbe",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/benzo-vase",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-brendon-schwarz-gros-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-brent-schwarz-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-astor-gros-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-arvin-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-albion-gros-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-albion-medium-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-albion-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-addison-gros-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-addison-medium-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-addison-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-irvin-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-lennox-medium-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-lennox-klein-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-fenton-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-fenton-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-ebrill-gold-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-elwyn-gold-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-earlson-gold-medium-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-eaden-silber-gros-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-emeray-silber-medium-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windicht-aylmer-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-avius-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-barlow-auf-stander-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-barey-auf-stander-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-lorne-marmor-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windicht-oswell-schwarz-gold-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-osmin-schwarz-gold-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-oxley-silber-gross-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-orvyn-silber-klein-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-orren-schwarz-nickel-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-bradney-silber-klein-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-bartley-gold-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-barton-gold-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-havily-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-holly-gold-mit-grosem-geweih-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-harvey-gold-met-kleinem-geweih-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hurricane-shane-mit-gehammertem-glas-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-evi-mit-4-kerzenhaltern-silber-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-fay-mit-3-kerzenhaltern-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-macy-mit-geweih-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-emmy-silber-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/windlicht-joni-silber-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blumentopf-lowen-2er-set-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-kenza-medium-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-kenza-klein-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-ayleen-klein-schwarz-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-aletha-gros-weis-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-alesha-weis-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-loyd-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-chalice-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-mayla-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-densley-gros-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vaes-delmor-medium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-dailen-gros-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-danito-medium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-selah-aluminium-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-sjarel-aluminium-gros-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-siara-medium-aluminium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-milano-gold-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-lucino-gold-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-mirthe-gold-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-kyan-gold-klein-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-flynn-aluminium-champagne-gold-chameleon-500-green-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-maese-gros-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-jaylen-light-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-yuna-gros-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-leah-klein-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-grazia-aluminium-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-carlos-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-cedric-60o-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-maevy-schwarz-110o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-maesa-schwarz-90o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-maeron-schwarz-70o-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-snake-fiona-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-dorine-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-xeon-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-marila-mit-25-spiegeln-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-madlen-mit-federn-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-macynn-mit-federn-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-maevy-gold-110o-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-maesa-gold-90o-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-maeron-gold-70o-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-bara-mit-goldrand-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-maloe-55o-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-maylynn-43o-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-jeff-met-12-spiegeln-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-coley-mit-15-runden-spiegeln-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-birche-mit-16-spiegeln-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-isaiha-mit-4-runden-spiegeln-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-jazzey-60o-gros-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-cass-rund-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-morse-rund-54o-gros-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-montel-rund-41o-klein-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/spiegel-kensington-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jindy-off-white-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jorsy-gold-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jowi-pink-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jason-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jayda-40x60-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jill-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jaylinn-45x45-copenhagen-900-boucle-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jennifer-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-joshua-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jesper-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jake-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jasmine-40x60-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jessica-40x60-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jaims-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jacen-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jezz-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jesca-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jenthel-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jenai-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jaxi-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-jadi-45x45-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/pampasgras-pink-klein-12-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/pampasgras-cream-gros-6-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/rosita-sessel",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/dalia-taupe-12-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gras-palm-honey-8-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gras-palm-pink-8-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gras-pampas-pink-gros-6-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gras-pampas-rosa-klein-12-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bloem-kirschbluten-pink-12-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gras-palm-taupe-8-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/baumwoll-zweige-24-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blatt-strelizie-24-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blume-dalia-honey-12-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blume-dalia-rosa-12-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blume-rose-pink-12-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blume-rose-light-pink-12-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blume-rose-cream-12-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blume-rose-rosa-weis-18-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blume-rose-lila-24-stuck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/blume-rose-flieder-24-stueck-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/uhr-bradlee-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/uhr-bradon-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/uhr-magee-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-timo-gros-black-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-xemm-bronze-gold-gros-bronze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-xemm-bronze-gold-medium-bronze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-xemm-bronze-gold-klein-bronze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-chenna-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-ross-medium-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-ross-klein-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-divon-gros-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-dixon-medium-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-maira-gold-gros-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-majlen-gold-medium-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-magly-gold-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-raylan-schwarz-gold-gros-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-roann-schwarz-gold-medium-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-reen-schwarz-gold-klein-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-oxley-palme-gros-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-niles-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-orvil-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-bray-gros-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/luxury-deluxe-teppich-mit-glitzer-250x300-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tischleuchte-jeremy-silber-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tischleuchte-jeremy-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-teddy-pink-50x50-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-teddy-schwarz-40x60-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-teddy-schwarz-50x50-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-teddy-weis-40x60-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-teddy-weis-50x50-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kissen-teddy-pink-40x60-zzz-roze-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tablett-cady-zwart-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/krokodil-deko-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/affe-dekogold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hund-miro-deko-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gepard-deko-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schlange-deko-medium-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schlange-deko-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deko-stander-dilan-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deko-stander-dilan-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/faux-koralle-efron-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/faux-koralle-aaron-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gorilla-music-tonka-silver-finish-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hund-spike-pink-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hund-spike-schwarz-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gorilla-koko-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/krokodil-mojo-schwarz-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/krokodil-crocky-schwarz-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/schmuckkastchen-penny-snake-2er-setblack-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/faux-koralle-milan-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/faux-koralle-adam-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gorilla-music-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/sanduhr-tyrek-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/tablett-belen-2er-set-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-kaelyn-brushed-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-derex-rund-silber-gold-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-derial-eckig-gold-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-deonne-rund-gold-goud-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-danell-rund-silber-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-willson-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-greyson-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-bryson-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-scott-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-aiden-eckig-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-jayden-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-owen-eckig-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-westin-metall-silver-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-everson-metall-eckig-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/wanduhr-johnson-metall-brown-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-silas-ivoor-goud-groot-ivoor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-silas-gold-medium-ivoor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-silas-gold-klein-ivoor-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-dewi-palme-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/einhorn-deko-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-vince-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-hugo-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-emma-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-duke-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-lenn-medium-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/vase-lenn-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-ricky-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-ricky-silber-silver-finish-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deko-stander-alina-gros-weis-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deko-stander-alina-klein-weis-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deko-stander-anthelia-weis-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deko-stander-anthelia-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/qualle-djelly-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/buchstutze-kala-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hund-graffity-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hund-miro-schwarz-black-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/hund-miro-pink-pink-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/panther-yaro-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/deko-staender-nora-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/koralle-jesse-klein-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/koralle-noah-white-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/koralle-aryan-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/gorilla-deko-gold-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/luftballon-hund-deko-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/krokodil-medium-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/krokodil-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lowe-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/bison-jaylee-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-emily-velvet-graphite-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-emily-velvet-graphite-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-emily-velvet-graphite-richmond-interiors-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-emily-velvet-graphite-richmond-interiors-4",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-emily-velvet-graphite-richmond-interiors-5",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-jaylinn-velvet-taupe-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-jaylinn-velvet-taupe-richmond-interiors-2",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/lampenschirm-jaylinn-velvet-taupe-richmond-interiors-3",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-brandy-klein-champagne-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-jenyd-gros-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-jeral-klein-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-lyle-leopard-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-set-welton-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-morley-marmor-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-morton-marmor-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-dex-tintenfisch-gold-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-everlee-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-carlee-gros-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/kerzenhalter-clay-klein-gold-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/pflanzenstander-jalyce-2er-set-richmond-interiors",
    component: component_45stubBk71oMB3Bx
  },
  {
    name: component_45stubBk71oMB3BxMeta?.name,
    path: "/produkt/teakholz-leiter",
    component: component_45stubBk71oMB3Bx
  }
]