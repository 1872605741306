import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["contentBlocks","inspiration_Entry","link","product","rooms_Entry","seomatic_Category","seomatic_Entry","seomatic_Product","about","blog","blogOverview","brand","brandsOverview","cartConfirmation","cartData","cartOverview","contact","content","filters","forms","home","inspiration","inspirationOverview","interiorAdvice","interiorStyles","interiorStylesOverview","interiorViewers","interiorViewersOverview","magazine","navigation","notFound","productIds","products","roomsOverview","search","stores"]}
export const GqlAbout = (...params) => useGql()('about', ...params)
export const GqlBlog = (...params) => useGql()('blog', ...params)
export const GqlBlogOverview = (...params) => useGql()('blogOverview', ...params)
export const GqlBrand = (...params) => useGql()('brand', ...params)
export const GqlBrandsOverview = (...params) => useGql()('brandsOverview', ...params)
export const GqlCartConfirmation = (...params) => useGql()('cartConfirmation', ...params)
export const GqlCartData = (...params) => useGql()('cartData', ...params)
export const GqlCartOverview = (...params) => useGql()('cartOverview', ...params)
export const GqlContact = (...params) => useGql()('contact', ...params)
export const GqlContent = (...params) => useGql()('content', ...params)
export const GqlFilters = (...params) => useGql()('filters', ...params)
export const GqlForms = (...params) => useGql()('forms', ...params)
export const GqlHome = (...params) => useGql()('home', ...params)
export const GqlInspiration = (...params) => useGql()('inspiration', ...params)
export const GqlInspirationOverview = (...params) => useGql()('inspirationOverview', ...params)
export const GqlInteriorAdvice = (...params) => useGql()('interiorAdvice', ...params)
export const GqlInteriorStyles = (...params) => useGql()('interiorStyles', ...params)
export const GqlInteriorStylesOverview = (...params) => useGql()('interiorStylesOverview', ...params)
export const GqlInteriorViewers = (...params) => useGql()('interiorViewers', ...params)
export const GqlInteriorViewersOverview = (...params) => useGql()('interiorViewersOverview', ...params)
export const GqlMagazine = (...params) => useGql()('magazine', ...params)
export const GqlNavigation = (...params) => useGql()('navigation', ...params)
export const GqlNotFound = (...params) => useGql()('notFound', ...params)
export const GqlProduct = (...params) => useGql()('product', ...params)
export const GqlProductIds = (...params) => useGql()('productIds', ...params)
export const GqlProducts = (...params) => useGql()('products', ...params)
export const GqlRoomsOverview = (...params) => useGql()('roomsOverview', ...params)
export const GqlSearch = (...params) => useGql()('search', ...params)
export const GqlStores = (...params) => useGql()('stores', ...params)