<template>
  <app>
    <section class="pt-125 md:pt-175 lg:pt-200">
      <div class="container">
        <h1
          class="wysiwyg-title text-3xl md:text-4xl lg:text-5xl xl:text-6xl"
          v-html="error.statusCode === 404 ? data?.entry?.heading : $t('general.error')"
        />

        <div
          v-if="error.statusCode === 404"
          class="wysiwyg max-w-screen-lg mt-30"
          v-html="data?.entry?.text"
        />

        <NuxtLink
          v-if="data?.entry?.button?.url"
          :to="data.entry.button.url"
          :target="data.entry.button.target"
          class="inline-flex items-center text-2xl font-serif underline space-x-5 group mt-25 text-secondary"
        >
          <span>{{ data.entry.button.text }}</span>
          <chevronIcon class="w-20 -rotate-90 group-hover:translate-x-5 transition" />
        </NuxtLink>
      </div>
    </section>
  </app>
</template>

<script setup>
import {
  useI18n,
  useAsyncGql,
} from '#imports';

// General icons
import chevronIcon from '@/assets/img/icon-chevron.svg';

// Props
defineProps({
  error: {
    type: Object,
    default: null,
  },
});

// Data
const { locale } = useI18n({ useScope: 'global' });
const { data } = await useAsyncGql('notFound', {
  site: locale.value,
});
</script>
