<template>
  <Html :lang="locale">
    <div>
      <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#e8e3dd 0%,#7b7670 100%)" />
      <contact-bar :data="data" />
      <NuxtLayout>
        <slot />
      </NuxtLayout>
    </div>
  </Html>
</template>

<script setup>
import {
  useI18n,
  useHead,
  useAsyncGql,
} from '#imports';

// Favicons
import appleTouchIcon from '@/assets/img/favicon/apple-touch-icon.png';
import favicon32 from '@/assets/img/favicon/favicon-32x32.png';
import favicon16 from '@/assets/img/favicon/favicon-16x16.png';

// Data
const { locale } = useI18n({ useScope: 'global' });
const { data } = await useAsyncGql('navigation', { site: locale.value }, { key: 'navigation' });

// Meta
useHead({
  meta: [
    { name: 'google-site-verification', content: 'QQADZMC0IHmgeDygDGyR2kKH0yjhDu77HxJO_Pqz2QI' },
    { name: 'google-site-verification', content: 'LHOSpO2RLi0BdY2EVI3PwtOG44cbCXss-l6oU9flrOs' },
    { name: 'viewport', content: 'viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no' },
  ],
  link: [
    {
      href: appleTouchIcon, sizes: '180x180', rel: 'apple-touch-icon',
    },
    {
      href: favicon32, sizes: '32x32', rel: 'icon', type: 'image/png',
    },
    {
      href: favicon16, sizes: '16x16', rel: 'icon', type: 'image/png',
    },
  ],
});
</script>
