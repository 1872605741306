export default () => {
  /**
   * Deformat phone number.
   *
   * @param {String} value
   */
  const phone = (value) => value.replace(/[\s()-]/g, '').replace(/\+/g, '00');

  return { phone };
};
