import { defineNuxtRouteMiddleware, useState } from '#imports';

export default defineNuxtRouteMiddleware(() => {
  useState('loading').value = null;
  useState('adding').value = null;
  useState('scrolled').value = false;
  useState('showLanguages').value = false;
  useState('showDrawer').value = false;
  useState('showCart').value = false;
  useState('showDropdown').value = false;
  useState('menuIsOpen').value = false;
  useState('showFurniture').value = false;
  useState('showRooms').value = false;
  useState('showAccessories').value = false;
  useState('showInspiration').value = false;
  useState('contactBarIsOpen').value = false;
});
